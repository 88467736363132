import { Grid, Card, Typography, Button, Chip } from "@mui/material";
import { GridActionsCellItem, GridOverlay } from "@mui/x-data-grid-pro";
import {
  DeleteHolidayInput,
  HolidayResponseItem,
  S12DoctorListItem,
} from "@s12solutions/types";
import ProTable from "components/proTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/HighlightOff";

import React, { useCallback, useEffect, useState } from "react";
import { notEmpty } from "common/functions";
import dayjs, { Dayjs } from "dayjs";
import TimeAwayItemPopup from "./TimeAwayItemPopup";
import { useAPI, useAuth, useOnMount, usePopups } from "hooks";
import {
  DATE_FORMAT_WITH_TIME,
  EVENTUAL_CONSISTENCY_TIMEOUT,
  TYPE_ERROR,
} from "common/constants/common";
import {
  CONFIRM_DELETED,
  DOCTOR_DISABLED,
  UNEXPECTED_ERROR_MESSAGE,
  TIME_AWAY_DELETED
} from "common/constants/messages";
import { useNavigate, useLocation } from "react-router-dom";

interface DoctorFormProps {
  doctorId: string;
  handleCancel: () => void;
}

export interface EventItem {
  id: string;
  start: Dayjs;
  end: Dayjs;
  notes?: string | null;
  visible: boolean;
  postcode?: string;
  s12DoctorHolidaysId: string;
}

const DoctorTimeAwaySelectForm: React.FC<DoctorFormProps> = ({
  doctorId,
  handleCancel,
}) => {
  const readOnly = useAuth().user?.attributes["custom:limit_admin"];
  const location: any = useLocation();
  const navigate = useNavigate();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
    handleCancel();
  }
  const { handleConfirmation, handleBannerMessage, handleSnackbar, closeSnackbar } = usePopups();
  const [pageLoading, setPageLoading] = useState(false);

  const {
    trigger: loadS12Doctor,
    data,
    loading,
    error,
  } = useAPI<
    S12DoctorListItem | null,
    {
      id: string;
    }
  >({
    method: "GET",
    fieldName: "getS12Doctor",
    args: {
      id: doctorId,
    },
    manual: true,
  });

  const { trigger: deleteHoliday, loading: deleteLoading } = useAPI<
    HolidayResponseItem | null,
    { input: DeleteHolidayInput }
  >({
    method: "PUT",
    fieldName: "deleteHoliday",
    manual: true,
  });

  const [open, setOpen] = useState(false);

  const [current, setCurrent] = useState<EventItem[]>([]);
  const [upcoming, setUpcoming] = useState<EventItem[]>([]);

  const [eventItem, setEventItem] = useState<EventItem | null>(null);

  useOnMount(() => {
    loadS12Doctor();
  });

  useEffect(() => {
    if (error) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [error, handleBannerMessage]);

  const close = () => {
    setOpen(!open);
    setEventItem(null);
    setPageLoading(true);
    setTimeout(() => {
      loadS12Doctor();
      setPageLoading(false);
    }, EVENTUAL_CONSISTENCY_TIMEOUT);
  };

  useEffect(() => {
    if (
      data &&
      !loading &&
      !deleteLoading &&
      !pageLoading &&
      data &&
      data.holidays &&
      data.holidays.items
    ) {
      const now = dayjs();
      setCurrent(
        data.holidays.items
          .filter(notEmpty)
          .map((h) => ({ ...h, start: dayjs(h.start), end: dayjs(h.end) }))
          .filter((hol) => hol.start.diff(now) <= 0 && hol.end.diff(now) > 0)
          .sort(
            (a, b) => b.start.toDate().valueOf() - a.start.toDate().valueOf()
          )
      );
      setUpcoming(
        data.holidays.items
          .filter(notEmpty)
          .map((h) => ({ ...h, start: dayjs(h.start), end: dayjs(h.end) }))
          .filter((hol) => hol.start.diff(now) > 0)
          .sort(
            (a, b) => b.start.toDate().valueOf() - a.start.toDate().valueOf()
          )
      );
    } else {
      setCurrent([]);
      setUpcoming([]);
    }

    return () => {
      setCurrent([]);
      setUpcoming([]);
    };
  }, [data, deleteLoading, loading, pageLoading]);

  const handleDelete = useCallback(
    (id: string) => {
      handleConfirmation(CONFIRM_DELETED, () => {
        deleteHoliday({
          input: {
            id: id,
          },
        })
          .then((result) => {
            if (result && result.data && result.data.id) {
              handleBannerMessage("success", TIME_AWAY_DELETED);
              setPageLoading(true);
              setTimeout(() => {
                loadS12Doctor();
                setPageLoading(false);
              }, EVENTUAL_CONSISTENCY_TIMEOUT);
            } else {
              handleBannerMessage("error", UNEXPECTED_ERROR_MESSAGE);
            }
          })
          .catch(() => {
            handleBannerMessage("error", UNEXPECTED_ERROR_MESSAGE);
          });
      });
    },
    [deleteHoliday, handleBannerMessage, handleConfirmation, loadS12Doctor]
  );

  // Display a 'Doctor Disabled' snackbar if the doctors disabled field is true. 
  useEffect(() => {
    if (
      data?.disabled
    ) {
      handleSnackbar(TYPE_ERROR, DOCTOR_DISABLED, -1, true);
    }
    return () => {
      closeSnackbar();
    };
  }, [
    closeSnackbar,
    handleBannerMessage,
    handleSnackbar,
    data?.disabled
  ]);

  return (
    <>
      {data && (
        <TimeAwayItemPopup
          open={open}
          close={close}
          userDoctor={data}
          selectedEvent={eventItem}
          refetch={loadS12Doctor}
        />
      )}

      <Grid
        xs={12}
        m={1}
        p={2}
        component={Card}
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderRadius: 1,
          minHeight: window.innerHeight - 85,
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item>
                <Typography variant="h1">Manage Time Away</Typography>
              </Grid>
              {data?.disabled && (
                <Grid item>
                  <Chip
                    style={{ borderRadius: ".3rem" }}
                    label="Disabled"
                    color="error"
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item container sm={6} spacing={3}>
                <Grid container item sm={12} spacing={2}>
                  <Grid item sm>
                    <Typography variant="body1">GMC</Typography>
                    <Typography variant="h3">
                      {data?.id ?? "Loading"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item sm={12} spacing={2}>
                  <Grid item sm>
                    <Typography variant="body1">Name</Typography>
                    <Typography variant="h3">
                      {data?.name ?? "Loading"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container sm={6} spacing={3}>
                <Grid container item sm={12} spacing={2}>
                  <Grid item sm>
                    <Typography variant="body1">Email</Typography>
                    <Typography variant="h3">
                      {data?.email ?? "Loading"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item sm={12} spacing={2}>
                  <Grid item sm>
                    <Typography variant="body1">Phone</Typography>
                    <Typography variant="h3">
                      {data?.phone ?? "Loading"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item container sm={3} spacing={3}>
                <Grid item>
                  <Button
                    id="addTimeAway"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      setOpen(true);
                      setEventItem(null);
                    }}
                    disabled={readOnly}
                  >
                    Add Time Away
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id="cancelTimeAway"
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={handleCancel}
                    disabled={readOnly}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item sm={12}>
                <ProTable
                  loading={loading || deleteLoading}
                  rows={current}
                  noResultsOverlay={() => (
                    <GridOverlay>
                      <Typography>- No time away -</Typography>
                    </GridOverlay>
                  )}
                  columns={[
                    {
                      field: "actions",
                      type: "actions",
                      width: 20,
                      getActions: (params: any) => [
                        <GridActionsCellItem
                          id={"editCurrentTimeAway".concat(params.row.id)}
                          data-test-id={"editCurrentTimeAway".concat(
                            params.row.id
                          )}
                          icon={<EditIcon color="primary" />}
                          label="Schedule"
                          onClick={() => {
                            setOpen(true);
                            setEventItem(params.row);
                          }}
                          disabled={readOnly}
                        />,
                      ],
                    },
                    {
                      field: "actions2",
                      type: "actions",
                      width: 20,
                      getActions: (params: any) => [
                        <GridActionsCellItem
                          id={"deleteCurrentTimeAway".concat(params.row.id)}
                          data-test-id={"deleteCurrentTimeAway".concat(
                            params.row.id
                          )}
                          icon={<DeleteIcon color="error" />}
                          label="Schedule"
                          onClick={() => handleDelete(params.row.id)}
                          disabled={readOnly}
                        />,
                      ],
                    },
                    {
                      headerName: "Current Time Away",
                      field: "currentTimeAway",
                      minWidth: 200,
                      flex: 1,
                      valueGetter: (params) => {
                        return {
                          start: params.row.start,
                          end: params.row.end,
                        };
                      },
                      renderCell: (params: any) =>
                        dayjs(params.row.start)
                          .format(DATE_FORMAT_WITH_TIME)
                          .concat(" - ")
                          .concat(
                            dayjs(params.row.end).format(DATE_FORMAT_WITH_TIME)
                          ),

                      sortComparator: (a, b) =>
                        dayjs(b.start).toDate().valueOf() -
                        dayjs(a.start).toDate().valueOf(),
                    },
                    {
                      headerName: "Notes",
                      field: "currentNotes",
                      valueGetter: (params) => params.row.notes,
                      minWidth: 200,
                      flex: 1,
                    },
                  ]}
                  onClickRowData={(event: any) => {
                    if (!readOnly) {
                      setOpen(true);
                      setEventItem(event);
                    }
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <ProTable
                  loading={loading || deleteLoading || pageLoading}
                  rows={upcoming}
                  noResultsOverlay={() => (
                    <GridOverlay>
                      <Typography>- No time away -</Typography>
                    </GridOverlay>
                  )}
                  columns={[
                    {
                      field: "actions",
                      type: "actions",
                      width: 20,
                      getActions: (params: any) => [
                        <GridActionsCellItem
                          id={"editForthcomingTimeAway".concat(params.row.id)}
                          data-test-id={"editForthcomingTimeAway".concat(
                            params.row.id
                          )}
                          icon={<EditIcon color="primary" />}
                          label="Schedule"
                          onClick={() => {
                            setOpen(true);
                            setEventItem(params.row);
                          }}
                          disabled={readOnly}
                        />,
                      ],
                    },
                    {
                      field: "actions2",
                      type: "actions",
                      width: 20,
                      getActions: (params: any) => [
                        <GridActionsCellItem
                          id={"deleteForthcomingTimeAway".concat(params.row.id)}
                          data-test-id={"deleteForthcomingTimeAway".concat(
                            params.row.id
                          )}
                          icon={<DeleteIcon color="error" />}
                          label="Schedule"
                          onClick={() => handleDelete(params.row.id)}
                          disabled={readOnly}
                        />,
                      ],
                    },
                    {
                      headerName: "Forthcoming Time Away",
                      field: "forthcomingTimeAway",
                      minWidth: 200,
                      flex: 1,
                      valueGetter: (params) => {
                        return {
                          start: params.row.start,
                          end: params.row.end,
                        };
                      },
                      renderCell: (params: any) =>
                        dayjs(params.row.start)
                          .format(DATE_FORMAT_WITH_TIME)
                          .concat(" - ")
                          .concat(
                            dayjs(params.row.end).format(DATE_FORMAT_WITH_TIME)
                          ),
                      sortComparator: (a, b) =>
                        dayjs(b.start).toDate().valueOf() -
                        dayjs(a.start).toDate().valueOf(),
                    },
                    {
                      headerName: "Notes",
                      field: "forthcomingNotes",
                      valueGetter: (params) => params.row.notes,
                      minWidth: 200,
                      flex: 1,
                    },
                  ]}
                  onClickRowData={(event: any) => {
                    if (!readOnly) {
                      setOpen(true);
                      setEventItem(event);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorTimeAwaySelectForm;
