import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Grid,
  Typography as Text,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Button,
  TextField,
  RadioGroup,
  Radio,
  InputAdornment,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import dayjs, { Dayjs } from "dayjs";
import engb from "dayjs/locale/en-gb";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  StyledInput,
  StyledCheckBox,
  StyledSelect,
} from "components/uiElements";
import SuccessMessage from "components/successMessage";
import {
  AvailabilityObj,
  convertInputDataToMutationInput,
  ManageAvailabilityType,
  RepeatWeeklyOccurrenceOption,
} from "common/functions";
import {
  AvailabilityResponseItem,
  CreateAvailabilityInput,
  EventResponse,
  S12DoctorListItem,
  UpdateAvailabilityInput,
} from "@s12solutions/types";
import { isValidPostCode } from "common/validators";
import {
  AVAILABILITY_ADDED,
  AVAILABILITY_UPDATED,
  DayOfTheWeek,
  END_DATE_BEFORE_START_DATE,
  END_DATE_IN_PAST,
  END_TIME_NEED_TO_BE_WITHIN_A_DAY,
  END_TIME_SHOULD_AN_HOUR_AHEAD_START_TIME,
  INCORRECT_POSTAL_CODE,
  INCORRECT_POSTCODE,
  INVALID_DATE,
  INVALID_POSTCODE_ERROR_MESSAGE,
  NOTHING_TO_UPDATE,
  PLEASE_ENTER_END_DATE,
  REQUIRED_MANDATORY_FIELDS,
} from "common/constants/messages";
import { LoadingButton } from "@mui/lab";
import {
  INVALID_TIME,
  UNEXPECTED_ERROR_MESSAGE,
  INVALID_DATA,
} from "common/constants/messages";
import {
  DATE_FORMAT_ONLY_TIME,
  DATE_FORMAT_WITHOUT_TIME,
  EMPTY_STRING,
  EVENTUAL_CONSISTENCY_TIMEOUT,
  HYPHEN,
  TYPE_ERROR,
  TYPE_SUCCESS,
} from "common/constants/common";
import CopyToClipboardButton from "components/clipBoard";
import { isEqual } from "lodash";
import { useAPI } from "hooks";

type AvailabilityItemPopupProps = {
  open: boolean;
  close: () => void;
  availabilityObj: AvailabilityObj | null;
  isEditing: boolean;
  user: S12DoctorListItem;
  refetch: () => void;
};

const AvailabilityItemPopup: React.FC<AvailabilityItemPopupProps> = (props) => {
  const { open, close, availabilityObj, refetch, user, isEditing } = props;
  const dayOfTheWeekFn = (date: Dayjs) => {
    switch (date.day()) {
      case 1:
        return DayOfTheWeek.MONDAY;
      case 2:
        return DayOfTheWeek.TUESDAY;
      case 3:
        return DayOfTheWeek.WEDNESDAY;
      case 4:
        return DayOfTheWeek.THURSDAY;
      case 5:
        return DayOfTheWeek.FRIDAY;
      case 6:
        return DayOfTheWeek.SATURDAY;
      default:
        return DayOfTheWeek.SUNDAY;
    }
  };

  const initialState = useMemo(() => {
    if (availabilityObj) {
      return {
        startDate: dayjs(availabilityObj.startDate),
        startTime: dayjs(availabilityObj.startTime, DATE_FORMAT_ONLY_TIME),
        endDate: availabilityObj.hasEndDate
          ? dayjs(availabilityObj.endDate)
          : null,
        endTime: dayjs(availabilityObj.endTime, DATE_FORMAT_ONLY_TIME),
        notes: availabilityObj.notes,
        postcode: availabilityObj.postcode,
        mht: availabilityObj.mht,
        rota: availabilityObj.rota,
        oncall: availabilityObj.oncall,
        repeat: availabilityObj.repeatEvent,
        repeatWeeks: availabilityObj.repeatWeeklyFrequency,
        repeatType: availabilityObj.repeatWeeklyOccurrences,
        repeatWeekdays: availabilityObj.repeatWeekdays,
        shouldSetEndDate: availabilityObj.hasEndDate,
        overnight: availabilityObj.isOvernightEvent,
      };
    } else {
      return {
        startDate: dayjs(),
        startTime: dayjs()
          .set("minute", 0)
          .set("millisecond", 0)
          .set("seconds", 0)
          .add(1, "hour"),
        endDate: null,
        endTime: dayjs()
          .set("minute", 0)
          .set("millisecond", 0)
          .set("seconds", 0)
          .add(2, "hour"),
        notes: user.notes || EMPTY_STRING,
        postcode: user.locationName?.postcode || EMPTY_STRING,
        rota: HYPHEN,
        mht: HYPHEN,
        oncall: false,
        repeat: false,
        repeatWeeks: 1,
        repeatType: RepeatWeeklyOccurrenceOption.Once,
        repeatWeekdays: [dayOfTheWeekFn(dayjs())],
        shouldSetEndDate: false,
        overnight: false,
      };
    }
  }, [availabilityObj, user]);

  const constantState = useRef(initialState);

  const [startDate, setStartDate] = useState<Dayjs | null>(
    constantState.current.startDate
  );
  const [startTime, setStartTime] = useState<Dayjs | null>(
    constantState.current.startTime
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    constantState.current.endDate
  );
  const [endTime, setEndTime] = useState<Dayjs | null>(
    constantState.current.endTime
  );
  const [notes, setNotes] = useState<string>(constantState.current.notes);
  const [postcode, setPostcode] = useState<string>(
    constantState.current.postcode
  );
  const [mht, setMht] = useState<string>(constantState.current.mht);
  const [rota, setRota] = useState<string>(constantState.current.rota);
  const [repeat, setRepeat] = useState<boolean>(constantState.current.repeat);
  const [overnight, setOvernight] = useState<boolean>(
    constantState.current.overnight
  );
  const [oncall, setOncall] = useState<boolean>(constantState.current.oncall);
  const [repeatWeekdays, setRepeatWeekdays] = useState<string[]>(
    constantState.current.repeatWeekdays
  );
  const [repeatType, setRepeatType] = useState<RepeatWeeklyOccurrenceOption>(
    constantState.current.repeatType
  );
  const [repeatWeeks, setRepeatWeeks] = useState(
    constantState.current.repeatWeeks
  );
  const [shouldSetEndDate, setShouldSetEndDate] = useState(
    constantState.current.shouldSetEndDate
  );

  const [postcodeValidated, setPostcodeValidated] = useState(true);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [messageType, setMessageType] = useState<
    "error" | "success" | undefined
  >(undefined);
  const [startTimeError, setStartTimeError] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [endDateErrorText, setEndDateErrorText] = useState(EMPTY_STRING);
  const [endTimeErrorText, setEndTimeErrorText] = useState(EMPTY_STRING);

  const { trigger: validatePostcode, loading: postcodeLoading } = useAPI<
    EventResponse | null,
    {
      findExactMatch?: boolean;
      term?: string;
      postcode?: string;
      addressId?: string;
    }
  >({
    method: "GET",
    fieldName: "searchByPostcode",
    manual: true,
  });

  const { trigger: createAvailability, loading: createLoading } = useAPI<
    AvailabilityResponseItem | null,
    { input: CreateAvailabilityInput }
  >({
    method: "POST",
    fieldName: "createAvailability",
    manual: true,
  });

  const { trigger: updateAvailability, loading: updateLoading } = useAPI<
    AvailabilityResponseItem | null,
    { input: UpdateAvailabilityInput }
  >({
    method: "PUT",
    fieldName: "updateAvailability",
    manual: true,
  });

  useEffect(() => {
    if (!!initialState) {
      constantState.current = initialState;
    }
  }, [initialState]);

  useEffect(() => {
    setStartDate(constantState.current.startDate);
    setStartTime(constantState.current.startTime);
    setEndDate(constantState.current.endDate);
    setEndTime(constantState.current.endTime);
    setNotes(constantState.current.notes);
    setPostcode(constantState.current.postcode);
    setMht(constantState.current.mht);
    setRota(constantState.current.rota);
    setRepeat(constantState.current.repeat);
    setRepeatType(constantState.current.repeatType);
    setOvernight(constantState.current.overnight);
    setRepeatWeekdays(constantState.current.repeatWeekdays);
    setRepeatWeeks(constantState.current.repeatWeeks);
    setOncall(constantState.current.oncall);
    setShouldSetEndDate(constantState.current.shouldSetEndDate);
  }, [availabilityObj]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowMessage(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [showMessage]);

  const handleRepeatWeekdays = (event: any, weekDay: string) => {
    if (event.target.checked) {
      setRepeatWeekdays((v) => [...v, weekDay]);
    } else {
      setRepeatWeekdays((v) => [...v.filter((val) => !val.includes(weekDay))]);
    }
  };

  const mhtList = useMemo(() => {
    let arr =
      user.mhtEmployers?.items?.map((val) => {
        if (val) {
          return {
            key: val?.mht.id,
            value: val?.mht.organisation.name,
          };
        } else return {} as { key: string | number; value: string };
      }) || ([] as { key: string | number; value: string }[]);

    arr.push({ key: HYPHEN, value: "Independent" });
    return arr.sort((a, b) => a.value.localeCompare(b.value));
  }, [user.mhtEmployers?.items]);

  const rotaList = useMemo(() => {
    let arr =
      user.mhtEmployers?.items
        ?.filter((val) => val?.mht.id === mht)[0]
        ?.mht.organisation.rotas?.map((val) => {
          if (val) {
            return {
              key: val.id,
              value: val.name,
            };
          } else return {} as { key: string; value: string };
        }) || ([] as { key: string; value: string }[]);

    if (arr.length > 0) arr.push({ key: HYPHEN, value: "None" });
    return arr.sort((a, b) => a.value.localeCompare(b.value));
  }, [mht, user.mhtEmployers?.items]);

  const handlePopupBannerMessage = (
    message: string,
    type: "success" | "error"
  ) => {
    setMessageType(type);
    setMessage(message);
    setShowMessage(true);
  };

  const handleAddUpdate = useCallback(async () => {
    try {
      if (
        !startDate ||
        !startTime ||
        !endTime ||
        !isValidPostCode(postcode) ||
        (shouldSetEndDate && !endDate) ||
        (repeat && repeatWeekdays.length === 0)
      ) {
        handlePopupBannerMessage(REQUIRED_MANDATORY_FIELDS, TYPE_SUCCESS);
        return;
      }

      if (
        startDateError ||
        endTimeError ||
        startTimeError ||
        (shouldSetEndDate && endDateError) ||
        !isValidPostCode(postcode)
      ) {
        handlePopupBannerMessage(
          `${INVALID_DATA} (${startDateError} ${endTimeError} ${startTimeError} ${shouldSetEndDate} ${endDateError} ${!isValidPostCode(
            postcode
          )})`,
          TYPE_ERROR
        );
        return;
      }

      let result = await validatePostcode({
        postcode: postcode,
      });

      if (
        !result ||
        !result.data ||
        !result.data.location ||
        !result.data.locationName
      ) {
        setPostcodeValidated(false);
        handlePopupBannerMessage(INCORRECT_POSTCODE, TYPE_ERROR);
        return;
      }
      let input = {
        ...convertInputDataToMutationInput(
          {
            ...(isEditing && availabilityObj ? { id: availabilityObj.id } : {}),
            postcode: postcode,
            mht: mht,
            oncall: oncall,
            rotaId: rota !== HYPHEN ? rota : undefined,
            rotaName:
              rota !== HYPHEN
                ? rotaList.find(
                    (val: { key: string; value: string }) => val.key === rota
                  )?.value
                : undefined,
            notes: notes,
            startDate: startDate.toDate(),
            startTime: startTime.format(DATE_FORMAT_ONLY_TIME),
            endDate: !!shouldSetEndDate && !!endDate ? endDate.toDate() : null,
            endTime: endTime.format(DATE_FORMAT_ONLY_TIME),
            repeatEvent: repeat,
            repeatWeeklyFrequency: repeat ? repeatWeeks : 1,
            repeatWeekdays: repeat
              ? repeatWeekdays
              : [dayOfTheWeekFn(startDate)],
            isOvernightEvent: overnight,
            repeatWeeklyOccurrences: repeatType,
            hasEndDate: shouldSetEndDate,
          },
          availabilityObj?.id || null,
          !isEditing && !availabilityObj?.id
            ? ManageAvailabilityType.CREATE
            : ManageAvailabilityType.UPDATE,
          user.id
        ),
        location: {
          lat: result.data.location.lat,
          lon: result.data.location.lon,
        },
        locationName: {
          postcode: result.data.locationName.postcode,
          city: result.data.locationName.city,
          address: result.data.locationName.address,
          addressNotes: result.data.locationName.addressNotes,
        },
      };
      if (!!isEditing) {
        if (!input.id) {
          handlePopupBannerMessage(UNEXPECTED_ERROR_MESSAGE, TYPE_ERROR);
          return;
        }
        if (
          !!availabilityObj &&
          dayjs(startDate).format(DATE_FORMAT_WITHOUT_TIME) ===
            dayjs(constantState.current.startDate).format(
              DATE_FORMAT_WITHOUT_TIME
            ) &&
          dayjs(endDate).format(DATE_FORMAT_WITHOUT_TIME) ===
            dayjs(constantState.current.endDate).format(
              DATE_FORMAT_WITHOUT_TIME
            ) &&
          dayjs(endTime).format(DATE_FORMAT_ONLY_TIME) ===
            dayjs(constantState.current.endTime).format(
              DATE_FORMAT_ONLY_TIME
            ) &&
          dayjs(startTime).format(DATE_FORMAT_ONLY_TIME) ===
            dayjs(constantState.current.startTime).format(
              DATE_FORMAT_ONLY_TIME
            ) &&
          constantState.current.mht === mht &&
          constantState.current.oncall === oncall &&
          constantState.current.overnight === overnight &&
          constantState.current.postcode === postcode &&
          constantState.current.repeat === repeat &&
          constantState.current.repeatType === repeatType &&
          isEqual(constantState.current.repeatWeekdays, repeatWeekdays) &&
          constantState.current.repeatWeeks === repeatWeeks &&
          constantState.current.rota === rota &&
          constantState.current.shouldSetEndDate === shouldSetEndDate &&
          constantState.current.notes.trim().valueOf() ===
            notes.trim().valueOf()
        ) {
          handlePopupBannerMessage(NOTHING_TO_UPDATE, TYPE_SUCCESS);
          return;
        }
        let output = await updateAvailability({
          input: { ...input, id: input.id },
        });

        if (!output || !output.data || !output.data.id) {
          handlePopupBannerMessage(UNEXPECTED_ERROR_MESSAGE, TYPE_ERROR);
          return;
        }
        setTimeout(refetch, EVENTUAL_CONSISTENCY_TIMEOUT);
        handlePopupBannerMessage(AVAILABILITY_UPDATED, TYPE_SUCCESS);
        constantState.current = {
          ...constantState.current,
          startDate: startDate,
          startTime: startTime,
          endDate: endDate,
          endTime: endTime,
          notes: notes,
          postcode: postcode,
          mht: mht,
          rota: rota,
          oncall: oncall,
          repeat: repeat,
          repeatWeeks: repeatWeeks,
          repeatType: repeatType,
          repeatWeekdays: repeatWeekdays,
          shouldSetEndDate: shouldSetEndDate,
          overnight: overnight,
        };
      } else {
        let output = await createAvailability({
          input: input,
        });

        if (!output || !output.data || !output.data.id) {
          handlePopupBannerMessage(UNEXPECTED_ERROR_MESSAGE, TYPE_ERROR);
          return;
        }
        setTimeout(refetch, EVENTUAL_CONSISTENCY_TIMEOUT);
        handlePopupBannerMessage(AVAILABILITY_ADDED, TYPE_SUCCESS);
        setStartDate(dayjs());
        setStartTime(
          dayjs()
            .set("minute", 0)
            .set("millisecond", 0)
            .set("seconds", 0)
            .add(1, "hour")
        );
        setEndDate(null);
        setEndTime(
          dayjs()
            .set("minute", 0)
            .set("millisecond", 0)
            .set("seconds", 0)
            .add(2, "hour")
        );
        setNotes(user.notes || EMPTY_STRING);
        setPostcode(user.locationName?.postcode || EMPTY_STRING);
        setMht(HYPHEN);
        setRota(HYPHEN);
        setRepeat(false);
        setRepeatType(RepeatWeeklyOccurrenceOption.Once);
        setOvernight(false);
        setRepeatWeekdays([dayOfTheWeekFn(dayjs())]);
        setRepeatWeeks(1);
        setOncall(false);
        setShouldSetEndDate(false);
      }
    } catch (e) {
      handlePopupBannerMessage(UNEXPECTED_ERROR_MESSAGE, TYPE_ERROR);
    }
  }, [
    isEditing,
    availabilityObj,
    createAvailability,
    endDate,
    endDateError,
    endTime,
    endTimeError,
    mht,
    notes,
    oncall,
    overnight,
    postcode,
    refetch,
    repeat,
    repeatType,
    repeatWeekdays,
    repeatWeeks,
    rota,
    rotaList,
    shouldSetEndDate,
    startDate,
    startDateError,
    startTime,
    startTimeError,
    updateAvailability,
    user,
    validatePostcode,
  ]);

  useEffect(() => {
    if (showMessage) {
      let timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [close, showMessage]);

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth={false}
      PaperProps={{
        style: {
          borderRadius: 8,
          width: 800,
        },
      }}
    >
      <Box
        component="form"
        action="/"
        method="POST"
        onSubmit={(e: any) => {
          e.preventDefault();
          handleAddUpdate();
        }}
        noValidate
      >
        <DialogTitle>
          <Text>
            {!isEditing ? <>Add Availability</> : <>Update Availability</>}
          </Text>
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 3 }}>
          {showMessage && (
            <SuccessMessage message={message} type={messageType} />
          )}
          <Grid
            item
            mb={3}
            direction={"row"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledInput
              id="postcode"
              data-test-id="availability_postcode"
              name="postcode"
              label="Postcode"
              autoFill="undefined"
              required
              value={postcode}
              error={!isValidPostCode(postcode) || !postcodeValidated}
              errorText={
                !isValidPostCode(postcode)
                  ? INVALID_POSTCODE_ERROR_MESSAGE
                  : !postcodeValidated
                  ? INCORRECT_POSTAL_CODE
                  : EMPTY_STRING
              }
              onChange={(e: any) => {
                setPostcodeValidated(true);
                setPostcode(e.target.value);
              }}
              maxLength={255}
              endAdornment={
                <InputAdornment position="end">
                  <CopyToClipboardButton id="postcode" value={postcode} />
                </InputAdornment>
              }
            />
            <Box sx={{ width: "40%" }}>
              <StyledSelect
                id="availability-mht"
                data-test-id="availability-mht"
                label="MHT Association"
                value={mht}
                menuItems={mhtList}
                onChange={(event: any) => {
                  setMht(event.target.value);
                }}
                getKeyFromValue={true}
              />
            </Box>
          </Grid>
          {mht !== HYPHEN && (
            <Grid
              item
              mb={3}
              direction={"row"}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <StyledCheckBox
                id="availability-oncall"
                data-test-id="availability-oncall"
                label="On call"
                checked={oncall}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setOncall(event.target.checked);
                }}
              />

              {oncall && mht !== HYPHEN && rotaList.length > 0 && (
                <Box sx={{ width: "50%" }}>
                  <StyledSelect
                    id="availability-rota"
                    data-test-id="availability-rota"
                    label="Rota"
                    value={rota}
                    menuItems={rotaList}
                    onChange={(event: any) => {
                      setRota(event.target.value);
                    }}
                    getKeyFromValue={true}
                  />
                </Box>
              )}
            </Grid>
          )}
          <Grid item mb={3} sx={{}}>
            <StyledInput
              id="availability_note"
              data-test-id="availability_note"
              name="Availability Note"
              label="Notes"
              value={notes}
              multiline
              maxRows={10}
              minRows={2}
              onChange={(e: any) => {
                setNotes(e.target.value);
              }}
              maxLength={255}
            />
          </Grid>
          <Grid
            item
            mb={2}
            direction={"row"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box mr={2}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={{ ...engb, weekStart: 1 }}
              >
                <DesktopDatePicker
                  data-test-id="availability-start-date"
                  label="Start Date"
                  inputFormat={DATE_FORMAT_WITHOUT_TIME}
                  openTo="day"
                  views={["year", "month", "day"]}
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  onError={(reason) => {
                    if (reason === "invalidDate") {
                      setStartDateError(true);
                    } else {
                      setStartDateError(false);
                    }
                  }}
                  onAccept={() => {
                    setStartDateError(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="availability-start-date-input"
                      data-test-id="availability-start-date-input"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={startDateError || !startDate}
                      helperText={
                        startDateError || !startDate
                          ? INVALID_DATE
                          : EMPTY_STRING
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box mr={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  label="Start Time"
                  data-test-id="availability-start-time"
                  value={startTime}
                  onChange={(newValue) => {
                    setStartTime(newValue);
                  }}
                  onError={(reason) => {
                    if (reason === "invalidDate") {
                      setStartTimeError(true);
                    } else {
                      setStartTimeError(false);
                    }
                  }}
                  onAccept={() => {
                    setStartTimeError(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="availability-start-time-input"
                      data-test-id="availability-start-time-input"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={startTimeError || !startTime}
                      helperText={
                        startTimeError || !startTime
                          ? INVALID_TIME
                          : EMPTY_STRING
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  data-test-id="availability-end-time"
                  label={`End Time ${overnight ? "(next day)" : EMPTY_STRING}`}
                  value={endTime}
                  onChange={(newValue) => {
                    setEndTime(newValue);
                  }}
                  onError={(reason) => {
                    switch (reason) {
                      case "invalidDate":
                        setEndTimeError(true);
                        setEndTimeErrorText(INVALID_TIME);
                        break;
                      case "maxTime":
                        setEndTimeError(true);
                        setEndTimeErrorText(END_TIME_NEED_TO_BE_WITHIN_A_DAY);
                        break;
                      case "minTime":
                        setEndTimeError(true);
                        setEndTimeErrorText(
                          END_TIME_SHOULD_AN_HOUR_AHEAD_START_TIME
                        );
                        break;
                      default:
                        setEndTimeError(false);
                        setEndTimeErrorText(EMPTY_STRING);
                        break;
                    }
                  }}
                  minTime={!overnight ? startTime?.add(1, "hour") : null}
                  maxTime={overnight ? startTime : null}
                  onAccept={() => {
                    setEndTimeError(false);
                    setEndTimeErrorText(EMPTY_STRING);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="availability-end-time-input"
                      data-test-id="availability-end-time-input"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={endTimeError || !endTime}
                      helperText={endTimeErrorText}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid
            item
            mb={2}
            direction={"row"}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "self-start",
            }}
          >
            <StyledCheckBox
              label="Overnight Availability"
              id="availability-overnight"
              data-test-id="availability-overnight"
              checked={overnight}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setOvernight(event.target.checked);
              }}
            />
            <StyledCheckBox
              label="Repeat Event"
              id="availability-repeat"
              data-test-id="availability-repeat"
              checked={repeat}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (!event.target.checked) {
                  if (!availabilityObj) {
                    setRepeatType(RepeatWeeklyOccurrenceOption.Once);
                    setRepeatWeekdays([dayOfTheWeekFn(dayjs())]);
                    setRepeatWeeks(1);
                    setShouldSetEndDate(false);
                    setEndDate(null);
                  } else {
                    setRepeatType(constantState.current.repeatType);
                    setRepeatWeekdays(constantState.current.repeatWeekdays);
                    setRepeatWeeks(constantState.current.repeatWeeks);
                    setShouldSetEndDate(constantState.current.shouldSetEndDate);
                    setEndDate(constantState.current.endDate);
                  }
                }

                setRepeat(event.target.checked);
              }}
            />
          </Grid>
          {repeat && (
            <>
              <Grid item mb={1}>
                <Text sx={{ fontWeight: "bold", fontSize: 18 }}>Repeat</Text>
                <Grid
                  item
                  mb={1}
                  mt={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "self-start",
                  }}
                >
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.MONDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.MONDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.MONDAY)}
                    label={DayOfTheWeek.MONDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.MONDAY)
                    }
                  />
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.TUESDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.TUESDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.TUESDAY)}
                    label={DayOfTheWeek.TUESDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.TUESDAY)
                    }
                  />
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.WEDNESDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.WEDNESDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.WEDNESDAY)}
                    label={DayOfTheWeek.WEDNESDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.WEDNESDAY)
                    }
                  />
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.THURSDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.THURSDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.THURSDAY)}
                    label={DayOfTheWeek.THURSDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.THURSDAY)
                    }
                  />
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.FRIDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.FRIDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.FRIDAY)}
                    label={DayOfTheWeek.FRIDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.FRIDAY)
                    }
                  />
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.SATURDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.SATURDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.SATURDAY)}
                    label={DayOfTheWeek.SATURDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.SATURDAY)
                    }
                  />
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.SUNDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.SUNDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.SUNDAY)}
                    label={DayOfTheWeek.SUNDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.SUNDAY)
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                direction={"row"}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "self-start",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={repeatType}
                  onChange={(e) => {
                    if (e.target.value === RepeatWeeklyOccurrenceOption.Once) {
                      if (!availabilityObj) {
                        setRepeatWeeks(1);
                        setShouldSetEndDate(false);
                        setEndDate(null);
                      } else {
                        setRepeatWeeks(constantState.current.repeatWeeks);
                        setShouldSetEndDate(
                          constantState.current.shouldSetEndDate
                        );
                        setEndDate(constantState.current.endDate);
                      }
                    }
                    setRepeatType(
                      e.target.value as RepeatWeeklyOccurrenceOption
                    );
                  }}
                >
                  <FormControlLabel
                    id="availability-once"
                    data-test-id="availability-once"
                    value={RepeatWeeklyOccurrenceOption.Once}
                    control={
                      <Radio
                        id="availability-once-radio"
                        data-test-id="availability-once-radio"
                      />
                    }
                    label="Only this week"
                  />
                  <FormControlLabel
                    id="availability-multiple"
                    data-test-id="availability-multiple"
                    value={RepeatWeeklyOccurrenceOption.Multiple}
                    control={
                      <Radio
                        id="availability-multiple-radio"
                        data-test-id="availability-multiple-radio"
                      />
                    }
                    label="Repeat over multiple weeks"
                  />
                </RadioGroup>
              </Grid>
            </>
          )}
          {repeatType === RepeatWeeklyOccurrenceOption.Multiple && repeat && (
            <>
              <Grid item mb={1}>
                <Grid
                  item
                  container
                  mb={1}
                  mt={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  xs={12}
                  spacing={1}
                >
                  <Grid item xs={2}>
                    <Text>Repeat every</Text>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="availability-weeks"
                      data-test-id="availability-weeks"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 999,
                        },
                      }}
                      value={repeatWeeks}
                      onChange={(e) => setRepeatWeeks(parseInt(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs>
                    <Text>week(s)</Text>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                mb={2}
                direction={"row"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <StyledCheckBox
                    checked={shouldSetEndDate}
                    label="Set end date"
                    id="availability-has-end-date"
                    data-test-id="availability-has-end-date"
                    handleChange={(e: any) => {
                      if (!e.target.checked) {
                        setEndDate(null);
                      } else {
                        setEndDate(constantState.current.endDate);
                      }
                      setShouldSetEndDate(e.target.checked);
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                mb={2}
                direction={"row"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                container
              >
                <Grid item xs={4}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={{ ...engb, weekStart: 1 }}
                  >
                    <DesktopDatePicker
                      data-test-id="availability-end-date"
                      label="End Date"
                      openTo="day"
                      views={["year", "month", "day"]}
                      inputFormat={DATE_FORMAT_WITHOUT_TIME}
                      value={endDate}
                      disablePast
                      disabled={!shouldSetEndDate}
                      onChange={(newValue) => {
                        setEndDate(newValue);
                      }}
                      minDate={startDate}
                      onError={(reason) => {
                        if (reason === "invalidDate") {
                          setEndDateError(true);
                          setEndDateErrorText(INVALID_DATE);
                        } else if (reason === "minDate") {
                          setEndDateError(true);
                          setEndDateErrorText(END_DATE_BEFORE_START_DATE);
                        } else if (reason === "disablePast") {
                          setEndDateError(true);
                          setEndDateErrorText(END_DATE_IN_PAST);
                        } else {
                          setEndDateError(false);
                          setEndDateErrorText(EMPTY_STRING);
                        }
                      }}
                      onAccept={() => {
                        setEndDateError(false);
                        setEndDateErrorText(EMPTY_STRING);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="availability-end-date-input"
                          data-test-id="availability-end-date-input"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={(!endDate && shouldSetEndDate) || endDateError}
                          helperText={
                            !endDate && shouldSetEndDate
                              ? PLEASE_ENTER_END_DATE
                              : endDateErrorText
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <DialogActions>
            <Button
              id="availability-clear"
              data-test-id="availability-clear"
              sx={{ marginRight: 1 }}
              disabled={false}
              variant="outlined"
              onClick={() => {
                if (!availabilityObj) {
                  setStartDate(dayjs());
                  setStartTime(
                    dayjs()
                      .set("minute", 0)
                      .set("millisecond", 0)
                      .set("seconds", 0)
                      .add(1, "hour")
                  );
                  setEndDate(null);
                  setEndTime(
                    dayjs()
                      .set("minute", 0)
                      .set("millisecond", 0)
                      .set("seconds", 0)
                      .add(2, "hour")
                  );
                  setNotes(user.notes || EMPTY_STRING);
                  setPostcode(user.locationName?.postcode || EMPTY_STRING);
                  setMht(HYPHEN);
                  setRota(HYPHEN);
                  setRepeat(false);
                  setRepeatType(RepeatWeeklyOccurrenceOption.Once);
                  setOvernight(false);
                  setRepeatWeekdays([dayOfTheWeekFn(dayjs())]);
                  setRepeatWeeks(1);
                  setOncall(false);
                  setShouldSetEndDate(false);
                } else {
                  setStartDate(constantState.current.startDate);
                  setStartTime(constantState.current.startTime);
                  setEndDate(constantState.current.endDate);
                  setEndTime(constantState.current.endTime);
                  setNotes(constantState.current.notes);
                  setPostcode(constantState.current.postcode);
                  setMht(constantState.current.mht);
                  setRota(constantState.current.rota);
                  setRepeat(constantState.current.repeat);
                  setRepeatType(constantState.current.repeatType);
                  setOvernight(constantState.current.overnight);
                  setRepeatWeekdays(constantState.current.repeatWeekdays);
                  setRepeatWeeks(constantState.current.repeatWeeks);
                  setOncall(constantState.current.oncall);
                  setShouldSetEndDate(constantState.current.shouldSetEndDate);
                }
              }}
            >
              Clear
            </Button>
            <LoadingButton
              id="availability-submit"
              data-test-id="availability-submit"
              loading={
                (!isEditing ? createLoading : updateLoading) || postcodeLoading
              }
              sx={{ marginRight: 2 }}
              variant="contained"
              type="submit"
            >
              {!isEditing ? <>Add Availability</> : <>Update Availability</>}
            </LoadingButton>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default React.memo(AvailabilityItemPopup);
