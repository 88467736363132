import React, { useState, useCallback, useEffect } from "react";

// Material UI
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

// Material UI Icons
import HomeIcon from "@mui/icons-material/Home";

// Custom
import { primaryMain } from "common/constants/theme";
import { useStyles } from "./styles";
import config from "./config";
import { useNavigate, useLocation } from "react-router-dom";
import { HOME } from "layout/routes/RouteConstants";
import {
  Collapse,
  IconButton,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuth } from "hooks";
import theme from "theme";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { DRAWER_WIDTH } from "common/constants/ui";
import { compact } from "lodash";

interface SideMenuProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideMenu: React.FC<SideMenuProps> = ({ drawerOpen, setDrawerOpen }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth();

  const readOnly = user?.attributes["custom:limit_admin"];
  const readOnlyBlacklist = [
    "/amhp/add",
    "/amhp-team/add",
    "/doctor/add",
    "/stat-forms/users",
    "/sites/add",
    "/sites/link",
  ];

  const [open, setOpen] = useState(false);
  const [menuName, setMenuName] = useState("Home");
  const [selectedMenu, setSelectedMenu] = useState("Home");
  const handleToggleOpen = useCallback(
    (e) => {
      setMenuName(e);
      setOpen(!open);
    },
    [open]
  );

  useEffect(() => {
    if (
      location?.pathname.includes("/signin") ||
      location?.pathname.toString() === "/"
    ) {
      setMenuName("Home");
      navigate(HOME);
    }
  }, [location, navigate]);

  useEffect(() => {
    let selectedItem = compact(
      config?.map((i) => {
        let tem = i.items.filter((k) => k.href === location.pathname);
        if (tem.length) {
          return i;
        }
        return null;
      })
    ).filter((n) => n)[0];
    setSelectedMenu(selectedItem?.name || "Home");
    setMenuName(selectedItem?.name || "Home");
  }, [location.pathname, selectedMenu]);

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        closeAfterTransition={true}
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
          },
        }}
      >
        <div data-test-id="side-menu">
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => setDrawerOpen(false)}>
              {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </div>

          <Divider />
          <List>
            <React.Fragment key={"sidemenu-home"}>
              <ListItemButton
                onClick={() => {
                  setMenuName("Home");
                  navigate(HOME);
                }}
                data-test-id={"sidemenu-home"}
                component={Link}
                selected={selectedMenu === "Home"}
                mb={1}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText
                  sx={
                    selectedMenu === "Home"
                      ? {
                          "& .MuiListItemText-secondary": {
                            fontWeight: "bold",
                          },
                        }
                      : {}
                  }
                  secondary={"Home"}
                />
              </ListItemButton>
            </React.Fragment>
            {config?.map((data) => {
              return (
                <React.Fragment key={data.testId}>
                  <ListItemButton
                    data-test-id={data.testId}
                    onClick={() => handleToggleOpen(data.name)}
                    component={Link}
                    selected={selectedMenu === data.name}
                    mb={1}
                  >
                    <ListItemIcon>{data.icon}</ListItemIcon>
                    <ListItemText
                      sx={
                        selectedMenu === data.name || menuName === data.name
                          ? {
                              "& .MuiListItemText-secondary": {
                                fontWeight: "bold",
                              },
                            }
                          : {}
                      }
                      secondary={data.name}
                    />
                    {data.items ? (
                      <>
                        {menuName === data.name ||
                        selectedMenu === data.name ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </ListItemButton>
                  <Collapse
                    in={menuName === data.name || selectedMenu === data.name}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {data?.items
                        ?.filter((subData: any) =>
                          readOnly
                            ? !readOnlyBlacklist.includes(subData.href)
                            : true
                        )
                        .map((subData: any) => (
                          <React.Fragment key={subData.testId}>
                            <ListItemButton
                              data-test-id={subData.testId}
                              component={Link}
                              className={classes.sideItemChild}
                              sx={
                                window.location.pathname === subData.href
                                  ? {
                                      padding: 0.5,
                                      "& .MuiListItemButton-root": {
                                        color: "white",
                                      },
                                    }
                                  : { padding: 0.5 }
                              }
                              onClick={() => {
                                if (window.location.pathname !== subData.href) {
                                  setSelectedMenu(data.name);
                                  navigate(subData.href);
                                } else {
                                  navigate(subData.href, {
                                    state: {
                                      isSameMenu: true,
                                    },
                                  });
                                }
                              }}
                            >
                              <ListItemText
                                sx={
                                  window.location.pathname === subData.href
                                    ? {
                                        margin: 0,
                                        "& .MuiListItemText-secondary": {
                                          fontSize: 12,
                                          color: primaryMain,
                                          fontWeight: "bold",
                                        },
                                      }
                                    : {
                                        margin: 0,
                                        "& .MuiListItemText-secondary": {
                                          fontSize: 12,
                                        },
                                      }
                                }
                                secondary={subData.name}
                              />
                            </ListItemButton>
                          </React.Fragment>
                        ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            })}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default React.memo(SideMenu);
