import React, { useState, useEffect } from "react";

// Material UI Imports
// import { Card, Grid, Typography as Text, Modal } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  Grid,
  Typography as Text,
  Modal,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Custom Imports
import { StyledSelect } from "components/uiElements";
import FilterModalContent from "components/FilterModal/FilterModal";
import { Box } from "@mui/system";
import { useAPI } from "hooks";
import useCategoryAndFilters from "./utils/useCategoryAndFilters";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@date-io/date-fns";

// Category lib
const categories = [
  { value: "Amhp", key: "amhpProfile" },
  { value: "Amhp Team", key: "amhpTeam" },
  { value: "doctor", key: "DoctorVisit" },
  { value: "Claim", key: "Claim" },
  { value: "Assessment", key: "Assessment" },
  { value: "Reporting", key: "Reporting" },
];

const filterOptions = [
  { value: "ccgId" },
  { value: "amhpId" },
  { value: "doctorId" },
  { value: "claimAmhpId" },
  { value: "claimStatus" },
  { value: "DateRange" },
];

const filterAmhpTeamOptions = [
  { value: "amhpId" },
  { value: "ccgId" },
  { value: "amhpName" },
  { value: "amhpEmail" },
  { value: "amhpPhone" },
  { value: "amhpStatus" },
  { value: "createdAt" },
  { value: "amhpStatus" },
];

const filterClaimOptions = [
  { value: "claimDisplayId" },
  { value: "claimStatus" },
  { value: "amhpTeamId" },
  { value: "assigneeName" },
  { value: "assigneeId" },
  { value: "claimCsuId" },
  { value: "email" },
  { value: "id" },
  { value: "createdAt" },
  { value: "status" },
  { value: "claimDoctorId" },
  { value: "claimCsuId" },
  { value: "paid" },
];

const allowedKeys = [
  "amhpId",
  "amhpTeamId",
  "assessmentDate",
  "assigneeId",
  "assigneeName",
  "ccgId",
  "claimDisplayId",
  "claimOrganisationId",
  "claimStatus",
  "claimVisitId",
  "createdAt",
  "createdBy",
  "doctorId",
  "doctorVisitClaimId",
  "email",
  "id",
  "locationOfAssessment",
  "siteId",
  "status",
  "time",
  "updatedBy",
  "updatedAt",
  "vatRegistration",
  "visitDate",
  "name",
  "partialPostcode",
  "mileage",
  "location",
  "locationType",
  "locationText",
  "isIndependentDoctor",
  "hadVisitAdded",
  "claimPostcode",
  "claimDoctorId",
  "assessmentPostcode",
  "assessmentFee",
  "address",
];

const SIFTBase: React.FC = () => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(200);
  const [selectedMetadataId, setSelectedMetadataId] = useState<string | null>(
    null
  );
  const [selectedMetadata, setSelectedMetadata] = useState<any>(null);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const {
    category,
    setCategory,
    filters,
    setFilters,
    listCategories,
    isLoading,
    setIsLoading,
    error,
  } = useCategoryAndFilters("");

  useEffect(() => {
    setIsLoading(true);
    fetchTable().finally(() => {
      setIsLoading(false);
    });
  }, [category, filters, page, pageSize, startDate, endDate, setIsLoading]);

  const { data, trigger: fetchTable } = useAPI({
    method: "GET",
    fieldName: "getAuditLog",
    args: {
      fieldName: "getAuditLog",
      category: casify(category),
      ...filters.reduce(
        (obj: any, item: any) => ({ ...obj, [item.field]: item.value }),
        {}
      ),
      limit: pageSize,
      offset: page * pageSize,
    },
    manual: true,
  });

  const handleSearchClick = () => {
    const filterParams: any = {
      ...filters.reduce(
        (base, filter) => ({ ...base, [filter.field]: filter.value }),
        {}
      ),
      category,
      fieldName: "getAuditLog",
    };

    if (startDate) {
      filterParams.startDate = startDate.getTime();
    }
    if (endDate) {
      filterParams.endDate = endDate.getTime();
    }

    fetchTable({
      ...filterParams,
      limit: pageSize,
      offset: page * pageSize,
    });
  };

  const handleClearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setFilters([]);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const handleMetadataClick = (
    metadataId: string,
    metadata: Record<string, any>
  ) => {
    if (selectedMetadataId === metadataId) {
      setSelectedMetadataId(null);
      setSelectedMetadata(null);
    } else {
      let filteredMetadata = Object.entries(metadata).map(([key, value]) => {
        if (
          Array.isArray(value) &&
          value.length > 0 &&
          typeof value[0] === "object"
        ) {
          return [
            key,
            value.map((item) => `${item.name}: ${item.value}`).join(", "),
          ];
        } else if (typeof value === "object" && value !== null) {
          return [key, JSON.stringify(value)];
        } else {
          return [key, value];
        }
      });
      setSelectedMetadataId(metadataId);
      setSelectedMetadata(filteredMetadata);
    }
  };

  const columns = [
    {
      field: "cognitoUser",
      headerName: "Agent",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
    {
      field: "origin",
      headerName: "Origin",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Timestamp (GMT)",
      flex: 1,
      valueGetter: (params: any) => {
        if (params.row.createdAt) {
          const dateObject = new Date(+params.row.createdAt);
          return dateObject.toLocaleString("en-EN");
        }
        return "";
      },
    },
    {
      field: "metadataDetails",
      headerName: "Metadata Details",
      flex: 1,
      renderCell: (params: any) => (
        <VisibilityIcon
          onClick={() => handleMetadataClick(params.id, params.row.metadata)}
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <Grid
          xs={12}
          m={1}
          p={2}
          component={Card}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid container m={0}>
            <Grid container spacing={3} sx={{ height: "fit-content" }}>
              <Grid item xs={12}>
                <Text variant="h1">Start search here...</Text>
              </Grid>
              <Grid item mt={1} xs={12}>
                <StyledSelect
                  label="Search Category"
                  value={category}
                  required={true}
                  menuItems={isLoading ? categories : listCategories}
                  onChange={(event: any) => {
                    setCategory(event.target.value);
                    setFilters([]);
                  }}
                  getKeyFromValue={true}
                />
              </Grid>
              {filters.length ? (
                <Grid item mt={1} xs={12}>
                  <Text variant="h3">Filters</Text>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {filters.map((filter: any) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          <p
                            onClick={() =>
                              setFilters(
                                filters.filter(
                                  (e: any) => e.field !== filter.field
                                )
                              )
                            }
                            style={{
                              border: "solid",
                              borderRadius: 5,
                              padding: 5,
                              borderColor: "lightGrey",
                              margin: 5,
                            }}
                          >
                            {filter.field} : "{filter.value}"
                          </p>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              ) : null}
              <Grid
                item
                mt={1}
                xs={12}
                container
                spacing={2}
                alignItems="center"
              >
                <Grid item>
                  <LoadingButton
                    disabled={!category}
                    variant="contained"
                    onClick={() => setShowFilterModal(true)}
                  >
                    Add New Filter
                  </LoadingButton>
                </Grid>

                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={handleSearchClick}
                  >
                    Search
                  </LoadingButton>
                </Grid>

                <Grid item>
                  <LoadingButton
                    variant="outlined"
                    onClick={handleClearFilters}
                  >
                    Clear Filters
                  </LoadingButton>
                </Grid>

                <Grid item>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={setStartDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>

                <Grid item>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={setEndDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </Grid>

              <Grid item mt={1} xs={12}>
                <Box sx={{ height: 800, width: "100%" }}>
                  <DataGridPro
                    rows={
                      data
                        ? data.map((d: any, i: number) => ({ id: i, ...d }))
                        : []
                    }
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    paginationMode="server"
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    loading={isLoading}
                  />
                </Box>
              </Grid>
            </Grid>
            {selectedMetadata && (
              <Dialog
                open={!!selectedMetadataId}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={() => setSelectedMetadataId(null)}
                sx={{
                  "& .MuiDialog-paper": {
                    minWidth: "600px",
                    backgroundColor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "16px",
                  },
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{
                    backgroundColor: "primary.main",
                    color: "primary.contrastText",
                    py: 1,
                  }}
                >
                  <h2>Metadata Details</h2>
                </DialogTitle>
                <DialogContent
                  sx={{
                    backgroundColor: "background.default",
                  }}
                >
                  <DialogContentText
                    id="alert-dialog-description"
                    sx={{
                      color: "text.primary",
                    }}
                  >
                    <div>
                      {selectedMetadata.length > 0 ? (
                        <ul>
                          {selectedMetadata
                            .filter(([key, _]: [string, any]) =>
                              allowedKeys.includes(key)
                            )
                            .map(([key, value]: [string, any]) => (
                              <Box
                                component="li"
                                key={key}
                                sx={{ mt: 1, typography: "body1" }}
                              >
                                <strong>{key}:</strong> {value}
                              </Box>
                            ))}
                        </ul>
                      ) : (
                        <p style={{ textAlign: "center", width: "100%" }}>
                          No metadata found
                        </p>
                      )}
                    </div>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            )}
          </Grid>
          <Modal
            open={showFilterModal}
            onClose={() => setShowFilterModal(false)}
          >
            <FilterModalContent
              category={category}
              listItems={
                category === "Claim"
                  ? filterClaimOptions
                  : category === "AmhpTeam"
                  ? filterAmhpTeamOptions
                  : filterOptions
              }
              filters={filters}
              setFilters={setFilters}
              onClose={() => setShowFilterModal(false)}
            />
          </Modal>
        </Grid>
      </div>
    </LocalizationProvider>
  );
};

// Camel cases words for the database
function casify(str: string) {
  if (str === "amhpprofile") return "amhpProfile";
  else if (str === "amhpteam") return "amhpTeam";
  else return str;
}

export default React.memo(SIFTBase);
