// Main application layout, setting up style, routes and manus
import React from "react";

// Material UI / Theme
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Custom Imports
import theme from "theme";
import Layout from "layout";
import { PopupsProvider } from "hooks";
import Confirmation from "components/confirmation";
import Snackbar from "components/snackbar";

// Web styles
import "react-datepicker/dist/react-datepicker.css";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <PopupsProvider>
            <Layout />
            <Confirmation />
            <Snackbar />
          </PopupsProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default React.memo(App);
