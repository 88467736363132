import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Grid,
  Typography as Text,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import dayjs, { Dayjs } from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { StyledInput, StyledCheckBox } from "components/uiElements";
import SuccessMessage from "components/successMessage";
import {
  CreateHolidayInput,
  HolidayResponseItem,
  S12DoctorListItem,
  UpdateHolidayInput,
} from "@s12solutions/types";
import { EventItem } from "pages/Doctor/ManageDoctorTimeAway/DoctorTimeAwaySelectForm";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import engb from "dayjs/locale/en-gb";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DATE_FORMAT_ONLY_TIME,
  EVENTUAL_CONSISTENCY_TIMEOUT,
} from "common/constants/common";
import {
  DATE_FORMAT_WITHOUT_TIME,
  DATE_FORMAT_WITH_TIME,
  EMPTY_STRING,
  TYPE_ERROR,
  TYPE_SUCCESS,
} from "common/constants/common";
import {
  END_DATE_BEFORE_START_DATE,
  END_TIME_BEFORE_START_TIME,
  INVALID_DATA,
  INVALID_DATE,
  INVALID_TIME,
  NOTHING_TO_UPDATE,
  START_DATE_IN_PAST,
  TIME_AWAY_SAVED,
  UNEXPECTED_ERROR_MESSAGE,
} from "common/constants/messages";
import { useAPI } from "hooks";

type TimeAwayItemPopupProps = {
  open: boolean;
  close: () => void;
  userDoctor: S12DoctorListItem;
  selectedEvent: EventItem | null;
  refetch: () => void;
};

const TimeAwayItemPopup: React.FC<TimeAwayItemPopupProps> = (props) => {
  const { open, close, userDoctor, selectedEvent, refetch } = props;

  let initialState = useMemo(() => {
    return {
      startDateTime: selectedEvent
        ? selectedEvent.start
        : dayjs().set("minute", 0).add(1, "hour"),
      endDateTime: selectedEvent
        ? selectedEvent.end
        : dayjs().set("minute", 0).add(2, "hour"),
      notes:
        selectedEvent && selectedEvent.notes
          ? selectedEvent.notes
          : EMPTY_STRING,
    };
  }, [selectedEvent]);

  const constantState = useRef(initialState);

  const [startDate, setStartDate] = useState<Dayjs | null>(
    constantState.current.startDateTime
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    constantState.current.endDateTime
  );
  const [startTime, setStartTime] = useState<Dayjs | null>(
    constantState.current.startDateTime
  );
  const [endTime, setEndTime] = useState<Dayjs | null>(
    constantState.current.endDateTime
  );
  const [notes, setNotes] = useState<string>(constantState.current.notes);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [messageType, setMessageType] = useState<
    "error" | "success" | undefined
  >(undefined);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const [startDateErrorMessage, setStartDateErrorMessage] =
    useState(EMPTY_STRING);
  const [startTimeErrorMessage, setStartTimeErrorMessage] =
    useState(EMPTY_STRING);
  const [endDateErrorMessage, setEndDateErrorMessage] = useState(EMPTY_STRING);
  const [endTimeErrorMessage, setEndTimeErrorMessage] = useState(EMPTY_STRING);
  const [isAllDay, setIsAllDay] = useState<boolean>(false);

  const { trigger: createHoliday, loading: createLoading } = useAPI<
    HolidayResponseItem | null,
    { input: CreateHolidayInput }
  >({
    method: "POST",
    fieldName: "createHoliday",
    manual: true,
  });

  const { trigger: updateHoliday, loading: updateLoading } = useAPI<
    HolidayResponseItem | null,
    { input: UpdateHolidayInput }
  >({
    method: "PUT",
    fieldName: "updateHoliday",
    manual: true,
  });

  useEffect(() => {
    if (!!initialState) {
      constantState.current = initialState;
    }
  }, [initialState]);

  useEffect(() => {
    if (open) {
      setShowMessage(false);
      setIsAllDay(false);
    }
  }, [open]);

  useEffect(() => {
    if (selectedEvent && selectedEvent.notes) {
      setNotes(constantState.current.notes);
    }
    if (selectedEvent) {
      setStartDate(constantState.current.startDateTime);
      setStartTime(constantState.current.startDateTime);
      setEndDate(constantState.current.endDateTime);
      setEndTime(constantState.current.endDateTime);

      if (
        constantState.current.startDateTime.format(DATE_FORMAT_ONLY_TIME) ===
          "00:00" &&
        constantState.current.endDateTime.format(DATE_FORMAT_ONLY_TIME) ===
          "23:59"
      ) {
        setIsAllDay(true);
      }
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (isAllDay) {
      setStartTime((val) => {
        return !!val
          ? val
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .set("millisecond", 0)
          : dayjs()
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .set("millisecond", 0);
      });
      setEndTime((val) => {
        return !!val
          ? val
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .set("millisecond", 999)
          : dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .set("millisecond", 999);
      });
    } else {
      setStartTime((val) => {
        return val
          ? selectedEvent
            ? val
                .set("hour", selectedEvent.start.hour())
                .set("minute", selectedEvent.start.minute())
            : val.set("hour", dayjs().hour()).set("minute", 0).add(1, "hour")
          : dayjs().set("minute", 0).add(1, "hour");
      });
      setEndTime((val) => {
        return val
          ? selectedEvent
            ? val
                .set("hour", selectedEvent.end.hour())
                .set("minute", selectedEvent.end.minute())
            : val.set("hour", dayjs().hour()).set("minute", 0).add(2, "hour")
          : dayjs().set("minute", 0).add(2, "hour");
      });
    }
  }, [isAllDay, selectedEvent]);

  const handlePopupBannerMessage = (
    message: string,
    type: "success" | "error"
  ) => {
    setMessageType(type);
    setMessage(message);
    setShowMessage(true);
  };

  useEffect(() => {
    if (showMessage) {
      let timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [close, showMessage]);

  const handleAddUpdateHoliday = useCallback(() => {
    if (
      !!startDate &&
      !!endDate &&
      !!startTime &&
      !!endTime &&
      endDateErrorMessage === EMPTY_STRING &&
      endTimeErrorMessage === EMPTY_STRING &&
      startDateErrorMessage === EMPTY_STRING &&
      startTimeErrorMessage === EMPTY_STRING
    ) {
      const endDateTime = dayjs(endDate)
        .set("hours", dayjs(endTime).hour())
        .set("minutes", dayjs(endTime).minute());
      const startDateTime = dayjs(startDate)
        .set("hours", dayjs(startTime).hour())
        .set("minutes", dayjs(startTime).minute());
      if (!!selectedEvent) {
        if (
          dayjs(startDateTime).format(DATE_FORMAT_WITH_TIME) ===
            dayjs(constantState.current.startDateTime).format(
              DATE_FORMAT_WITH_TIME
            ) &&
          dayjs(endDateTime).format(DATE_FORMAT_WITH_TIME) ===
            dayjs(constantState.current.endDateTime).format(
              DATE_FORMAT_WITH_TIME
            ) &&
          constantState.current.notes.trim().valueOf() ===
            notes.trim().valueOf()
        ) {
          handlePopupBannerMessage(NOTHING_TO_UPDATE, TYPE_SUCCESS);
        } else {
          updateHoliday({
            input: {
              id: selectedEvent.id,
              start: startDateTime.toISOString(),
              end: endDateTime.toISOString(),
              notes: notes,
              s12DoctorHolidaysId: userDoctor.id,
              visible: true,
            },
          })
            .then((result) => {
              if (result && result.data && result.data.id) {
                setTimeout(refetch, EVENTUAL_CONSISTENCY_TIMEOUT);
                constantState.current = {
                  startDateTime: startDateTime,
                  endDateTime: endDateTime,
                  notes: notes,
                };
                handlePopupBannerMessage(TIME_AWAY_SAVED, TYPE_SUCCESS);
              } else {
                handlePopupBannerMessage(UNEXPECTED_ERROR_MESSAGE, TYPE_ERROR);
              }
            })
            .catch(() => {
              handlePopupBannerMessage(UNEXPECTED_ERROR_MESSAGE, TYPE_ERROR);
            });
        }
      } else {
        createHoliday({
          input: {
            start: startDateTime.toISOString(),
            end: endDateTime.toISOString(),
            notes: notes,
            s12DoctorHolidaysId: userDoctor.id,
            visible: true,
          },
        })
          .then((result) => {
            if (result && result.data && result.data.id) {
              setTimeout(refetch, EVENTUAL_CONSISTENCY_TIMEOUT);
              setStartDate(dayjs());
              setEndDate(dayjs());
              setStartTime(dayjs().set("minute", 0).add(1, "hour"));
              setEndTime(dayjs().set("minute", 0).add(2, "hour"));
              setNotes(EMPTY_STRING);
              handlePopupBannerMessage(TIME_AWAY_SAVED, TYPE_SUCCESS);
            } else {
              handlePopupBannerMessage(UNEXPECTED_ERROR_MESSAGE, TYPE_ERROR);
            }
          })
          .catch(() => {
            handlePopupBannerMessage(UNEXPECTED_ERROR_MESSAGE, TYPE_ERROR);
          });
      }
    } else {
      handlePopupBannerMessage(INVALID_DATA, TYPE_ERROR);
    }
  }, [
    startDate,
    endDate,
    startTime,
    endTime,
    endDateErrorMessage,
    endTimeErrorMessage,
    startDateErrorMessage,
    startTimeErrorMessage,
    selectedEvent,
    notes,
    updateHoliday,
    userDoctor.id,
    refetch,
    createHoliday,
  ]);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={false}
      PaperProps={{
        style: {
          borderRadius: 8,
          width: 750,
        },
      }}
    >
      <DialogTitle>
        <Text>Time Away</Text>
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 3 }}>
        {showMessage && <SuccessMessage message={message} type={messageType} />}

        <Grid
          item
          mb={3}
          direction={"row"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={{ ...engb, weekStart: 1 }}
          >
            <DesktopDatePicker
              label="Start Date"
              data-test-id="time-away-start-date"
              openTo="day"
              disablePast
              inputFormat={DATE_FORMAT_WITHOUT_TIME}
              views={["year", "month", "day"]}
              value={startDate}
              onError={(reason) => {
                switch (reason) {
                  case "invalidDate":
                    setStartDateErrorMessage(INVALID_DATE);
                    break;
                  case "disablePast":
                    setStartDateErrorMessage(START_DATE_IN_PAST);
                    break;
                  default:
                    setStartDateErrorMessage(EMPTY_STRING);
                }
              }}
              onAccept={() => setStartDateErrorMessage(EMPTY_STRING)}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="time-away-start-date-input"
                  data-test-id="time-away-start-date-input"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={startDateErrorMessage}
                />
              )}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopTimePicker
              label="Start Time"
              data-test-id="time-away-start-time"
              value={startTime}
              disabled={isAllDay}
              onError={(reason) => {
                switch (reason) {
                  case "invalidDate":
                    setStartTimeErrorMessage(INVALID_TIME);
                    break;
                  default:
                    setStartTimeErrorMessage(EMPTY_STRING);
                }
              }}
              onAccept={() => setStartTimeErrorMessage(EMPTY_STRING)}
              onChange={(newValue) => {
                setStartTime(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="time-away-start-time-input"
                  data-test-id="time-away-start-time-input"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={startTimeErrorMessage}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          mb={1}
          direction={"row"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={{ ...engb, weekStart: 1 }}
          >
            <DesktopDatePicker
              label="End Date"
              data-test-id="time-away-end-date"
              inputFormat={DATE_FORMAT_WITHOUT_TIME}
              openTo="day"
              views={["year", "month", "day"]}
              onError={(reason) => {
                switch (reason) {
                  case "invalidDate":
                    setEndDateErrorMessage(INVALID_DATE);
                    break;
                  case "minDate":
                    setEndDateErrorMessage(END_DATE_BEFORE_START_DATE);
                    break;
                  default:
                    setEndDateErrorMessage(EMPTY_STRING);
                }
              }}
              onAccept={() => setEndDateErrorMessage(EMPTY_STRING)}
              minDate={startDate}
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="time-away-end-date-input"
                  data-test-id="time-away-end-date-input"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={endDateErrorMessage}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopTimePicker
              label="End Time"
              data-test-id="time-away-end-time"
              onAccept={() => setEndTimeErrorMessage(EMPTY_STRING)}
              value={endTime}
              disabled={isAllDay}
              onError={(reason) => {
                switch (reason) {
                  case "invalidDate":
                    setEndTimeErrorMessage(INVALID_TIME);
                    break;
                  case "minTime":
                  case "minutesStep":
                    setEndTimeErrorMessage(END_TIME_BEFORE_START_TIME);
                    break;
                  default:
                    setEndTimeErrorMessage(EMPTY_STRING);
                }
              }}
              onChange={(newValue) => {
                setEndTime(newValue);
              }}
              minTime={
                !!startDate && !!endDate && !startDate.isBefore(endDate, "date")
                  ? startTime
                  : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="time-away-end-time-input"
                  data-test-id="time-away-end-time-input"
                  helperText={endTimeErrorMessage}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item mb={1} sx={{}}>
          <StyledCheckBox
            id="time-away-is-all-day"
            data-test-id="time-away-is-all-day"
            checked={isAllDay}
            handleChange={() => setIsAllDay(!isAllDay)}
            label="All Day Event"
          />
        </Grid>
        <Grid item sx={{}}>
          <StyledInput
            id="time_away_note"
            data-test-id="time-away-note"
            name="Time Away Note"
            label="Notes"
            value={notes}
            multiline
            maxRows={10}
            minRows={4}
            onChange={(e: any) => {
              setNotes(e.target.value);
            }}
            maxLength={255}
          />
        </Grid>
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <DialogActions>
          <Button
            id="time-away-clear"
            data-test-id="time-away-clear"
            sx={{ marginRight: 1 }}
            disabled={false}
            variant="outlined"
            onClick={() => {
              if (!selectedEvent) {
                setStartDate(dayjs());
                setEndDate(dayjs());
                setStartTime(dayjs());
                setEndTime(dayjs().add(1, "hour"));
                setNotes(EMPTY_STRING);
              } else {
                setStartDate(constantState.current.startDateTime);
                setEndDate(constantState.current.endDateTime);
                setStartTime(constantState.current.startDateTime);
                setEndTime(constantState.current.endDateTime);
                setNotes(constantState.current.notes);
              }
              setIsAllDay(false);
            }}
          >
            Clear
          </Button>
          <LoadingButton
            id="time-away-submit"
            data-test-id="time-away-submit"
            sx={{ marginRight: 2 }}
            loading={!!selectedEvent ? updateLoading : createLoading}
            variant="contained"
            onClick={handleAddUpdateHoliday}
          >
            {!!selectedEvent ? <>Update Time Away</> : <>Add Time Away</>}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default React.memo(TimeAwayItemPopup);
