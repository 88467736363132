// import dayjs, { Dayjs } from "dayjs";
const RULE_DELIMITER: string = ";RRULE:";
const numericParts = ["INTERVAL", "BYHOUR", "BYMINUTE", "BYSECOND"];

class RRule {
  rule: string;
  parts: { [key: string]: string | number };
  startDate: string;
  endDate: string;
  repeatEvent: boolean;

  constructor(rrule: string) {
    this.rule = rrule;
    this.parts = {};
    this.startDate = "";
    this.endDate = "";
    this.repeatEvent = false;
    this.init(rrule);
  }

  init(rule: string) {
    this.rule = rule;
    this.parts = {};
    this.startDate = "";
    this.repeatEvent = false;
    this.parse();
  }

  set(rrule: string) {
    this.init(rrule);
  }

  parse() {
    if (this.rule) {
      // Split out string parts
      const [dtStart, ruleText] = this.rule.split(RULE_DELIMITER);
      const ruleParts = ruleText?.split(";") || [];
      ruleParts.forEach((part) => {
        const [key, value] = part.split("=");
        numericParts.includes(key) ? (this.parts[key] = parseInt(value, 10)) : (this.parts[key] = value);
      });

      // Set properties
      this.startDate = dtStart.split(":")[1];
      this.endDate = this.parts.UNTIL ? this.parts.UNTIL.toString() : "";
      this.repeatEvent = !!this.parts.FREQ;
    }
  }
}

export default RRule;

// DTSTART:20210409T140000Z;RRULE:FREQ=WEEKLY;WKST=MO;INTERVAL=1;BYDAY=FR,SA;BYHOUR=14;BYMINUTE=0;BYSECOND=1

//DTSTART:20210412T160000Z;RRULE:FREQ=WEEKLY;WKST=MO;INTERVAL=2;BYDAY=r,e,p,e,a,t,W,e,e,k,d,a,y,s,MO;BYHOUR=16;BYMINUTE=0;BYSECOND=1;UNTIL=20210531T235900Z
