/**
 * System messages
 * Constants for usage of messages throughout the system
 */

// Generic messages
export const UNEXPECTED_ERROR_MESSAGE = "Unexpected Error Ocurred";
export const UNEXPECTED_ERROR_MESSAGE_OR_AVAILABILITY_ALREADY_EXIST =
  "Unexpected Error Ocurred or Availability Already Exist";
export const INVALID_INPUTS_MESSAGE = "Invalid Inputs";
export const DELETED_SUCCESS_MESSAGE = "Deleted Successfully";
export const DISABLED_SUCCESS_MESSAGE = "Disabled Successfully";
export const REENABLED_SUCCESS_MESSAGE = "Reenabled Successfully";
export const CREATED_SUCCESS_MESSAGE = "Created Successfully";
export const INVALID_USER_MESSAGE = "There is no valid user";
export const UPDATED_SUCCESS_MESSAGE = "Updated Successfully";
export const SUCCESS_EMAIL_ADDED = "Email address added successfully!";
export const SUCCESS_ADDRESS_ADDED = "Address added successfully!";
export const ADDRESS_ALREADY_ADDED = "Address already added";
export const ADDRESS_ALREADY_UPDATED = "Address already updated";
export const EMAIL_ALREADY_ADDED = "Email address already added";
export const PHONE_ALREADY_ADDED = "Phone number already added";
export const SUCCESS_RECIPIENT_ADDED = "Approved recipient added successfully!";
export const NOTHING_TO_UPDATE = "Nothing to update";
export const USER_ALREADY_EXISTS_MESSAGE = "User Already Exists";

// Confirmation messages
export const CONFIRMATION_MESSAGE = "Are you sure you want to proceed?";
export const UNSAVED_WARNING =
  "You have unsaved changes, are you sure you want to navigate away?";
export const CONFIRM_DELETED = "Are you sure you want to delete this item?";
export const CONFIRM_DISABLE = "Are you sure you want to disable {name}?";
export const CONFIRM_CLEAR = "Are you sure you want to clear all data?";
export const CONFIRM_CANCEL = "Are you sure you want to cancel?";

// Validation messages
export const INVALID_OR_EXPIRED_GMC_MESSAGE =
  "Invalid GMC number or s.12 validity expired";
export const INVALID_OR_EXPIRED_GMC_MESSAGE_AND_DOCTOR_DISABLED =
  "THIS DOCTOR IS DISABLED and Invalid GMC number or s.12 validity expired";
export const DOCTOR_DISABLED = "THIS DOCTOR IS DISABLED";
export const USER_DELETED = "THIS USER HAS BEEN DELETED";
export const INVALID_NAME_ERROR_MESSAGE = "Please enter valid name";
export const SELECT_AT_LEAST_ONE_TEAM_MESSAGE =
  "Please select at least one team";
export const INVALID_GMC_FORMAT_MESSAGE =
  "Please enter a valid GMC number format";
export const INVALID_GMC_ERROR_MESSAGE = "Please enter a valid GMC number";
export const DUPLICATE_PHONE_ERROR_MESSAGE =
  "This number is already being used by another user in the platform";
export const ERROR_PHONE_CHECKING_FOR_DUPLICATES =
  "Error occurred when checking phone number for duplicates";
export const INVALID_PHONE_ERROR_MESSAGE = "Please enter a valid phone number";
export const INVALID_EMAIL_ERROR_MESSAGE = "Please enter a valid email address";
export const INVALID_PHONE_OR_INPUT_ERROR_MESSAGE =
  "Invalid phone input or entered phone number is invalid";
export const INVALID_EMAIL_OR_INPUT_ERROR_MESSAGE =
  "Invalid email input or entered email is invalid";
export const INVALID_POSTCODE_ERROR_MESSAGE = "Please enter a valid postcode";
export const NO_CHANGES =
  "There are no modifications to save, please review the form";
export const FILL_MANDATORY = "Mandatory field(s) is not added yet";
export const INVALID_DATA = "Data format(s) is invalid";
export const PASSWORD_MISMATCH =
  "Your password and confirmation password do not match";
export const PASSWORD_CHARACTERS =
  "Password must contain at least 8 characters";
export const PHONE_NUMBER_SAME =
  "Phone Number and Additional Phone Number should not be the same";

// Helper messages
export const PHONE_NUMBER_FORMAT_HELPER_MESSAGE =
  "Please use international format without the spaces, example: +447123453623";
export const SSO_SELECTED_HELPER_MESSAGE =
  "When selected, the email address is case sensitive, all capitalisation must match. ";

export const MICROSOFT_SSO_SELECTED_HELPER_MESSAGE =
  "Remember to ask a DEV team member to assign the user to Azure SSO. ";

// Organisation based messages
export const USER_ORG_UPDATED_SUCCESS_MESSAGE =
  "User Organisation(s) Successfully Updated ";
export const MHT_UPDATED_SUCCESS_MESSAGE = "MHT Successfully Updated";
export const MHT_CREATED_SUCCESS_MESSAGE = "MHT Successfully Added";

// AMHP
export const AMHP_MESSAGE = "AMHP User";

// Doctor
export const DOCTOR_MESSAGE = "S12 Doctor";

// Claims
export const CLAIMS_MESSAGE = "Claims User";

// GMC
export const INVALID_GMC_ERROR_MESSAGE_2 = "GMC number is Invalid";
export const EXPIRED_GMC_ERROR_PROMPT_MESSAGE_1 =
  "This doctor's GMC expired on ";
export const EXPIRED_GMC_ERROR_PROMPT_MESSAGE_2 =
  ". Do you still want to add them?";

// Stat form users
export const STAT_FORM_USER = "Stat Form User";
export const STAT_FORM_USER_EXISTS_MESSAGE =
  "User already exist under this StatForms user group";
export const STAT_FORM_USER_NOT_EXISTS_MESSAGE = "User doesn't exist";

// Success and name-injected messages
export const EXISTS_ERROR = "{name} Already Exists";
export const UPDATED_SUCCESS = "{name} Updated Successfully";
export const ADDED_SUCCESS = "{name} Added Successfully";
export const LINKED_SUCCESS = "{name} Linked Successfully";
export const DELETED_SUCCESS = "{name} Deleted Successfully";
export const DISABLED_SUCCESS = "{name} Disabled Successfully";
export const REENABLED_SUCCESS = "{name} Reenabled Successfully";
export const RESTORED_SUCCESS = "{name} Restored Successfully";
export const RESET_SUCCESS = "{name} Reset Successfully";

// Search
export const NO_MATCHING_RECORDS =
  "No matching records found. Please try again.";
export const LEAST_THREE_CHARACTERS =
  "Please enter at least three characters to begin search...";
export const ERROR_OCURRED = "Error ocurred fetching the results.";

// Time Away
export const TIME_AWAY_SAVED = "Time away saved";
export const TIME_AWAY_DELETED = "Time away deleted";
export const END_TIME_BEFORE_START_TIME = "End time needs to after start time";
export const END_DATE_BEFORE_START_DATE = "End date can't be before start date";
export const INVALID_TIME = "Invalid Time";
export const INVALID_DATE = "Invalid Date";

//Availability
export const AVAILABILITY_DELETED = "Availability deleted";
export const AVAILABILITY_UPDATED = "Availability updated";
export const AVAILABILITY_ADDED = "Availability added";
export const PLEASE_ENTER_END_DATE = "Please enter an end date";
export const START_DATE_IN_PAST = "Start Date can't be on past";
export const END_DATE_IN_PAST = "End Date can't be on past";
export const END_TIME_SHOULD_AN_HOUR_AHEAD_START_TIME =
  "End time need to be at least after 1 hour of start time";
export const END_TIME_NEED_TO_BE_WITHIN_A_DAY =
  "End time needs to be within 24 hours";
export const INCORRECT_POSTAL_CODE = "Incorrect postal code";
export const REQUIRED_MANDATORY_FIELDS = "Required mandatory fields";
export const INCORRECT_POSTCODE = "Entered postcode is incorrect";
export const GMC_ERROR_CONFIRM_ADDING_DOCTOR =
  "This GMC could not be verified.\nPlease check the National Database before adding.\nDo you still want to add this record?";

export const POSTCODE_ERROR_CONFIRM_ADDING_DOCTOR =
  "This postcode could not be verified.\nPlease check it manually before {typeContinuous}.\nDo you still want to {type} this record?";

export enum DayOfTheWeek {
  MONDAY = "MO",
  TUESDAY = "TU",
  WEDNESDAY = "WE",
  THURSDAY = "TH",
  FRIDAY = "FR",
  SATURDAY = "SA",
  SUNDAY = "SU",
}
