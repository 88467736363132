import {
  Grid,
  Card,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import { GridActionsCellItem, GridOverlay } from "@mui/x-data-grid-pro";
import {
  Availability,
  AvailabilityResponseItem,
  DeleteAvailabilityInput,
  S12DoctorListItem,
} from "@s12solutions/types";
import ProTable from "components/proTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/HighlightOff";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import notFalsy, {
  AvailabilityObj,
  convertAvailabilityTime,
  convertAvailabilityToAddUpdateData,
  convertRRuleDateTimeToUTCDate,
  displayAvailabilityInfo,
} from "common/functions";
import { RRule, RRuleSet, rrulestr } from "rrule";
import dayjs from "dayjs";
import AvailabilityItemPopup from "./AvailabilityItemPopup";
import { usePopups } from "hooks/usePopups";
import {
  EMPTY_STRING,
  EVENTUAL_CONSISTENCY_TIMEOUT,
  TYPE_ERROR,
  TYPE_SUCCESS,
} from "common/constants/common";
import {
  AVAILABILITY_DELETED,
  CONFIRM_DELETED,
  DOCTOR_DISABLED,
  UNEXPECTED_ERROR_MESSAGE,
} from "common/constants/messages";
import utc from "dayjs/plugin/utc";
import { useAPI, useAuth, useOnMount } from "hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { ContentCopy, ExpandMore } from "@mui/icons-material";
import { HtmlTooltip } from "components/tooltip";
import { HtmlBadge } from "components/badge";
import { compact } from "lodash";
dayjs.extend(utc);

interface DoctorFormProps {
  doctorId: string;
  handleCancel: () => void;
}

const DoctorAvailabilitySelectForm: React.FC<DoctorFormProps> = ({
  doctorId,
  handleCancel,
}) => {
  const readOnly = useAuth().user?.attributes["custom:limit_admin"];
  const location: any = useLocation();
  const navigate = useNavigate();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
    handleCancel();
  }
  const {
    handleConfirmation,
    handleBannerMessage,
    handleSnackbar,
    closeSnackbar,
  } = usePopups();
  const [pageLoading, setPageLoading] = useState(false);
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);
  const {
    trigger: loadS12Doctor,
    data,
    loading,
    error,
  } = useAPI<
    S12DoctorListItem | null,
    {
      id: string;
    }
  >({
    method: "GET",
    fieldName: "getS12Doctor",
    args: {
      id: doctorId,
    },
    manual: true,
  });

  const { trigger: deleteAvailability, loading: deleteLoading } = useAPI<
    AvailabilityResponseItem | null,
    { input: DeleteAvailabilityInput }
  >({
    method: "PUT",
    fieldName: "deleteAvailability",
    manual: true,
  });

  const [availabilityObj, setAvailabilityObj] =
    useState<AvailabilityObj | null>(null);

  const [isEditing, setIsEditing] = useState<boolean | null>(null);

  const [open, setOpen] = useState(false);

  useOnMount(() => {
    loadS12Doctor();
  });

  const close = () => {
    setOpen(!open);
    setAvailabilityObj(null);
    setPageLoading(true);
    setTimeout(() => {
      loadS12Doctor();
      setPageLoading(false);
    }, EVENTUAL_CONSISTENCY_TIMEOUT);
  };

  useEffect(() => {
    if (error) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [error, handleBannerMessage]);

  useEffect(() => {
    if (
      !loading &&
      !deleteLoading &&
      !pageLoading &&
      data &&
      data.availabilities &&
      data.availabilities.items
    ) {
      setAvailabilities(compact(data.availabilities.items));
    } else {
      setAvailabilities([]);
    }

    return () => {
      setAvailabilities([]);
    };
  }, [data, loading, deleteLoading, pageLoading]);
  const pastFilter = (av: any) =>
    dayjs(av.endDate.split("T")[0]).isBefore(dayjs(), "date");
  const upcomingEvents = useMemo(
    () =>
      availabilities
        .reduce(
          (
            prev: (Availability & {
              date: Date;
            })[],
            current: Availability
          ) => {
            const rruleSet = new RRuleSet(true);
            const rule = current.rrule.replace(";RRULE", "\nRRULE");
            rruleSet.rrule(rrulestr(rule));
            // Find ongoing, one-off events today
            const start = dayjs(rruleSet.dtstart());
            if (!pastFilter(current)) {
              prev.push({
                ...current,
                date: convertRRuleDateTimeToUTCDate(
                  new Date(start.toISOString())
                ),
              });
            }
            return prev;
          },
          []
        )
        .sort(
          (a, b) =>
            dayjs(RRule.fromString(b.rrule).options.dtstart)
              .toDate()
              .valueOf() -
            dayjs(RRule.fromString(a.rrule).options.dtstart).toDate().valueOf()
        ),
    [availabilities]
  );
  const rotaEvents = useMemo(
    () =>
      availabilities
        .filter(notFalsy)
        .filter((val) => !!val.rotaId && !!val.rotaName)
        .sort(
          (a, b) =>
            dayjs(RRule.fromString(b.rrule).options.dtstart)
              .toDate()
              .valueOf() -
            dayjs(RRule.fromString(a.rrule).options.dtstart).toDate().valueOf()
        ),
    [availabilities]
  );
  const pastEvents = useMemo(
    () =>
      availabilities
        .filter(notFalsy)
        .filter(pastFilter)
        // .filter((av) => av.endDate && dayjs(av.endDate.split("T")[0]).isAfter(dayjs().subtract(1, "month")))
        .sort(
          (a, b) =>
            dayjs(RRule.fromString(b.rrule).options.dtstart)
              .toDate()
              .valueOf() -
            dayjs(RRule.fromString(a.rrule).options.dtstart).toDate().valueOf()
        ),
    [availabilities]
  );
  const currentSeries = useMemo(
    () =>
      availabilities
        .filter(notFalsy)
        .filter((av) => !pastFilter(av) && av.rrule.includes("FREQ="))
        .sort(
          (a, b) =>
            dayjs(RRule.fromString(b.rrule).options.dtstart)
              .toDate()
              .valueOf() -
            dayjs(RRule.fromString(a.rrule).options.dtstart).toDate().valueOf()
        ),
    [availabilities]
  );

  const AvailabilityListItem = (
    availability: Availability & {
      date?: Date;
    }
  ) => {
    const startDate = dayjs(
      availability.rrule.split("DTSTART:")[1].split("T")[0]
    ).format("MMMM DD, YYYY");
    const startTime = convertAvailabilityTime(
      availability.rrule.split("DTSTART:")[1].split("T")[1].slice(0, 4)
    );

    const eventDate =
      !!availability.date && dayjs(availability.date).format("DD/MM/YYYY");
    const endTime = convertAvailabilityTime(availability.endHour.toString());
    const eventTime = `${startTime} - ${endTime}`;

    let rule = availability.rrule.split(/;(.+)/)[1]
      ? RRule.fromString(availability.rrule.split(/;(.+)/)[1])
      : null;

    const isOneOff = !availability.rrule.includes("FREQ=");
    return (
      <>
        {!!eventDate ? (
          <>
            <h5 className="font-weight-normal mb-0">{`${eventDate} (${
              availability.type
                ? displayAvailabilityInfo(availability.type, availability.mht)
                : EMPTY_STRING
            })`}</h5>
            &nbsp;
            <p className="mb-0">
              <small>{`${eventTime} ${
                !isOneOff && rule
                  ? "- starting " + startDate + " " + rule.toText()
                  : isOneOff
                  ? "One-Off on " + startDate
                  : EMPTY_STRING
              }`}</small>
            </p>
          </>
        ) : (
          <>
            <h5 className="font-weight-normal mb-0">{`${eventTime} (${
              availability.type
                ? displayAvailabilityInfo(availability.type, availability.mht)
                : EMPTY_STRING
            })`}</h5>
            &nbsp;
            <p className="mb-0">
              {isOneOff || !rule ? (
                <small>One-Off on {startDate}</small>
              ) : (
                <small>{`starting ${startDate} ${rule.toText()}`}</small>
              )}
            </p>
          </>
        )}
      </>
    );
  };

  const handleDelete = useCallback(
    (id: string) => {
      handleConfirmation(CONFIRM_DELETED, () => {
        deleteAvailability({
          input: {
            id: id,
          },
        })
          .then((result) => {
            if (result && result.data && result.data.id) {
              handleBannerMessage(TYPE_SUCCESS, AVAILABILITY_DELETED);
              setPageLoading(true);
              setTimeout(() => {
                loadS12Doctor();
                setPageLoading(false);
              }, EVENTUAL_CONSISTENCY_TIMEOUT);
            } else {
              handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
            }
          })
          .catch(() => {
            handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
          });
      });
    },
    [deleteAvailability, handleBannerMessage, handleConfirmation, loadS12Doctor]
  );

  // Display a 'Doctor Disabled' snackbar if the doctors disabled field is true.
  useEffect(() => {
    if (data?.disabled) {
      handleSnackbar(TYPE_ERROR, DOCTOR_DISABLED, -1, true);
    }
    return () => {
      closeSnackbar();
    };
  }, [closeSnackbar, handleBannerMessage, handleSnackbar, data?.disabled]);

  return (
    <>
      {isEditing !== null && data && (
        <AvailabilityItemPopup
          open={open}
          close={close}
          user={data}
          isEditing={isEditing}
          availabilityObj={availabilityObj}
          refetch={loadS12Doctor}
        />
      )}
      <Grid
        xs={12}
        m={1}
        p={2}
        component={Card}
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderRadius: 1,
          minHeight: window.innerHeight - 85,
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item>
                <Typography variant="h1">Manage Availability</Typography>
              </Grid>
              {data?.disabled && (
                <Grid item>
                  <Chip
                    style={{ borderRadius: ".3rem" }}
                    label="Disabled"
                    color="error"
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item container sm={6} spacing={3}>
                <Grid container item sm={12} spacing={2}>
                  <Grid item sm>
                    <Typography variant="body1">GMC</Typography>
                    <Typography variant="h3">
                      {data?.id ?? "Loading"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item sm={12} spacing={2}>
                  <Grid item sm>
                    <Typography variant="body1">Name</Typography>
                    <Typography variant="h3">
                      {data?.name ?? "Loading"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container sm={6} spacing={3}>
                <Grid container item sm={12} spacing={2}>
                  <Grid item sm>
                    <Typography variant="body1">Email</Typography>
                    <Typography variant="h3">
                      {data?.email ?? "Loading"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item sm={12} spacing={2}>
                  <Grid item sm>
                    <Typography variant="body1">Phone</Typography>
                    <Typography variant="h3">
                      {data?.phone ?? "Loading"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item container sm={3} spacing={3}>
                <Grid item>
                  <Button
                    id="addAvailability"
                    data-test-id="addAvailability"
                    variant="contained"
                    color={"primary"}
                    size="large"
                    onClick={() => {
                      setOpen(true);
                      setIsEditing(false);
                    }}
                    disabled={readOnly}
                  >
                    Add Availability
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id="cancelAvailability"
                    data-test-id="cancelAvailability"
                    variant="outlined"
                    color={"primary"}
                    size="large"
                    onClick={handleCancel}
                    disabled={readOnly}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item sm={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Typography variant="h3">
                          Rota Availabilities
                        </Typography>
                      </Grid>
                      <Grid item>
                        <HtmlBadge counter={rotaEvents.length} />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ProTable
                      noResultsOverlay={() => (
                        <GridOverlay>
                          <Typography>- No availabilities - </Typography>
                        </GridOverlay>
                      )}
                      loading={loading || deleteLoading || pageLoading}
                      rows={rotaEvents || []}
                      columns={[
                        {
                          field: "actions",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Edit" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"editRotaAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"editRotaAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <EditIcon
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="editSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(true);
                                  setAvailabilityObj(
                                    convertAvailabilityToAddUpdateData(
                                      params.row
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions3",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Duplicate" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"duplicateRotaAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"duplicateRotaAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <ContentCopy
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="duplicateSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(false);
                                  setAvailabilityObj(
                                    convertAvailabilityToAddUpdateData(
                                      params.row
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions2",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Delete" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"deleteRotaAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"deleteRotaAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <DeleteIcon
                                    color={readOnly ? "disabled" : TYPE_ERROR}
                                  />
                                }
                                label="deleteSchedule"
                                onClick={() => handleDelete(params.row.id)}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          headerName: "Availability Summary",
                          field: "rotaAvailability",
                          valueGetter: (params) => {
                            return {
                              rrule: params.row.rrule,
                            };
                          },
                          renderCell: (params: any) =>
                            AvailabilityListItem(params.row as Availability),

                          sortComparator: (a, b) =>
                            dayjs(RRule.fromString(b.rrule).options.dtstart)
                              .toDate()
                              .valueOf() -
                            dayjs(RRule.fromString(a.rrule).options.dtstart)
                              .toDate()
                              .valueOf(),
                          minWidth: 200,
                          flex: 1,
                        },
                        {
                          headerName: "Rota Name",
                          field: "rotaName",
                          minWidth: 200,
                          flex: 1,
                        },
                        {
                          headerName: "Notes",
                          field: "rotaNotes",
                          valueGetter: (params) => params.row.notes,
                          minWidth: 200,
                          flex: 1,
                        },
                      ]}
                      onClickRowData={(event: any) => {
                        if (!readOnly) {
                          setOpen(true);
                          setIsEditing(true);
                          setAvailabilityObj(
                            convertAvailabilityToAddUpdateData(event)
                          );
                        }
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item sm={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Typography variant="h3">
                          Current and Upcoming Availabilities
                        </Typography>
                      </Grid>
                      <Grid item>
                        <HtmlBadge counter={upcomingEvents.length} />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ProTable
                      noResultsOverlay={() => (
                        <GridOverlay>
                          <Typography>- No availabilities - </Typography>
                        </GridOverlay>
                      )}
                      loading={loading || deleteLoading || pageLoading}
                      rows={upcomingEvents || []}
                      columns={[
                        {
                          field: "actions",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Edit" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"editCurrentAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"editCurrentAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <EditIcon
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="editSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(true);
                                  setAvailabilityObj(
                                    convertAvailabilityToAddUpdateData(
                                      params.row
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions3",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Duplicate" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"duplicateCurrentAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"duplicateCurrentAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <ContentCopy
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="duplicateSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(false);
                                  setAvailabilityObj(
                                    convertAvailabilityToAddUpdateData(
                                      params.row
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions2",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Delete" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"deleteCurrentAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"deleteCurrentAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <DeleteIcon
                                    color={readOnly ? "disabled" : TYPE_ERROR}
                                  />
                                }
                                label="deleteSchedule"
                                onClick={() => handleDelete(params.row.id)}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          headerName: "Availability Summary",
                          field: "currentAvailability",
                          valueGetter: (params) => {
                            return {
                              rrule: params.row.rrule,
                            };
                          },
                          renderCell: (params: any) =>
                            AvailabilityListItem(
                              params.row as Availability & {
                                date: Date;
                              }
                            ),
                          sortComparator: (a, b) =>
                            dayjs(RRule.fromString(b.rrule).options.dtstart)
                              .toDate()
                              .valueOf() -
                            dayjs(RRule.fromString(a.rrule).options.dtstart)
                              .toDate()
                              .valueOf(),

                          minWidth: 200,
                          flex: 1,
                        },
                        {
                          headerName: "Notes",
                          field: "currentNotes",
                          valueGetter: (params) => params.row.notes,
                          minWidth: 200,
                          flex: 1,
                        },
                      ]}
                      onClickRowData={(event: any) => {
                        if (!readOnly) {
                          setOpen(true);
                          setIsEditing(true);
                          setAvailabilityObj(
                            convertAvailabilityToAddUpdateData(event)
                          );
                        }
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item sm={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Typography variant="h3">
                          Recurring Availabilities
                        </Typography>
                      </Grid>
                      <Grid item>
                        <HtmlBadge counter={currentSeries.length} />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ProTable
                      noResultsOverlay={() => (
                        <GridOverlay>
                          <Typography>- No availabilities - </Typography>
                        </GridOverlay>
                      )}
                      loading={loading || deleteLoading || pageLoading}
                      rows={currentSeries || []}
                      columns={[
                        {
                          field: "actions",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Edit" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"editRecurringAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"editRecurringAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <EditIcon
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="editSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(true);
                                  setAvailabilityObj(
                                    convertAvailabilityToAddUpdateData(
                                      params.row
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions3",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Duplicate" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"duplicateRecurringAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"duplicateRecurringAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <ContentCopy
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="duplicateSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(false);
                                  setAvailabilityObj(
                                    convertAvailabilityToAddUpdateData(
                                      params.row
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions2",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Delete">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"deleteRecurringAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"deleteRecurringAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <DeleteIcon
                                    color={readOnly ? "disabled" : TYPE_ERROR}
                                  />
                                }
                                label="deleteSchedule"
                                onClick={() => handleDelete(params.row.id)}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          headerName: "Availability Summary",
                          field: "recurringAvailability",
                          valueGetter: (params) => {
                            return {
                              rrule: params.row.rrule,
                            };
                          },
                          renderCell: (params: any) =>
                            AvailabilityListItem(params.row as Availability),

                          sortComparator: (a, b) =>
                            dayjs(RRule.fromString(b.rrule).options.dtstart)
                              .toDate()
                              .valueOf() -
                            dayjs(RRule.fromString(a.rrule).options.dtstart)
                              .toDate()
                              .valueOf(),
                          minWidth: 200,
                          flex: 1,
                        },
                        {
                          headerName: "Notes",
                          field: "recurringNotes",
                          valueGetter: (params) => params.row.notes,
                          minWidth: 200,
                          flex: 1,
                        },
                      ]}
                      onClickRowData={(event: any) => {
                        if (!readOnly) {
                          setOpen(true);
                          setIsEditing(true);
                          setAvailabilityObj(
                            convertAvailabilityToAddUpdateData(event)
                          );
                        }
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item sm={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Typography variant="h3">
                          Past Availabilities
                        </Typography>
                      </Grid>
                      <Grid item>
                        <HtmlBadge counter={pastEvents.length} />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ProTable
                      noResultsOverlay={() => (
                        <GridOverlay>
                          <Typography>- No availabilities - </Typography>
                        </GridOverlay>
                      )}
                      loading={loading || deleteLoading || pageLoading}
                      rows={pastEvents || []}
                      columns={[
                        {
                          field: "actions",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Edit" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"editPastAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"editPastAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <EditIcon
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="editSchedule"
                                onClick={() => {
                                  setIsEditing(true);
                                  setAvailabilityObj(
                                    convertAvailabilityToAddUpdateData(
                                      params.row
                                    )
                                  );
                                  setOpen(true);
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions3",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Duplicate" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"duplicatePastAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"duplicatePastAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <ContentCopy
                                    color={readOnly ? "disabled" : "primary"}
                                  />
                                }
                                label="duplicateSchedule"
                                onClick={() => {
                                  setOpen(true);
                                  setIsEditing(false);
                                  setAvailabilityObj(
                                    convertAvailabilityToAddUpdateData(
                                      params.row
                                    )
                                  );
                                }}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          field: "actions2",
                          type: "actions",
                          width: 20,
                          getActions: (params: any) => [
                            <HtmlTooltip title="Delete" placement="top">
                              <GridActionsCellItem
                                disabled={readOnly}
                                id={"deletePastAvailability".concat(
                                  params.row.id
                                )}
                                data-test-id={"deletePastAvailability".concat(
                                  params.row.id
                                )}
                                icon={
                                  <DeleteIcon
                                    color={readOnly ? "disabled" : TYPE_ERROR}
                                  />
                                }
                                label="deleteSchedule"
                                onClick={() => handleDelete(params.row.id)}
                              />
                            </HtmlTooltip>,
                          ],
                        },
                        {
                          headerName: "Availability Summary",
                          field: "pastAvailability",
                          valueGetter: (params) => {
                            return {
                              rrule: params.row.rrule,
                            };
                          },
                          renderCell: (params: any) =>
                            AvailabilityListItem(params.row as Availability),
                          sortComparator: (a, b) =>
                            dayjs(RRule.fromString(b.rrule).options.dtstart)
                              .toDate()
                              .valueOf() -
                            dayjs(RRule.fromString(a.rrule).options.dtstart)
                              .toDate()
                              .valueOf(),
                          minWidth: 200,
                          flex: 1,
                        },
                        {
                          headerName: "Notes",
                          field: "pastNotes",
                          valueGetter: (params) => params.row.notes,
                          minWidth: 200,
                          flex: 1,
                        },
                      ]}
                      onClickRowData={(event: any) => {
                        if (!readOnly) {
                          setOpen(true);
                          setIsEditing(true);
                          setAvailabilityObj(
                            convertAvailabilityToAddUpdateData(event)
                          );
                        }
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorAvailabilitySelectForm;
