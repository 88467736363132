import {
  Button,
  Grid,
} from "@mui/material";
import { StyledInput } from "components/uiElements";
import { useAPI, usePopups } from "hooks";
import React, { useCallback, useState } from "react";
import { LoadingButton } from "@mui/lab";
import * as MESSAGES from "common/constants/messages";
import * as COMMON from "common/constants/common";
import {
  validateFormDataAsync,
  validateInput,
} from "utils/zod";
import { GlobalSettingsSchema } from "utils/zod/schemas/GlobalSettingsSchema";
import { FormErrors } from "common/types/formErrors";
import Progress from "components/cirularProgress/Progress";
import { useLocation, useNavigate } from "react-router-dom";

// Types
type GlobalSettingOptionProps = {
  id: string;
  handleCancel?: any;
};

type GetGlobalSettingByIdResponseItems = {
  id: string | undefined;
  value: string | undefined;
  admin: boolean | undefined;
  updatedBy: string | undefined;
  updatedAt: string | undefined;
};

type GetGlobalSettingByIdInput = {
  id: string | undefined;
};

// Main Component
const GlobalSettingOption: React.FC<GlobalSettingOptionProps> = ({
  id,
  handleCancel,
}) => {
  const { handleConfirmation, handleBannerMessage } = usePopups();
  // State
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [globalSettingData, setGlobalSettingData] =
    useState<GetGlobalSettingByIdResponseItems>();
  const [initialSettingValue, setInitialSettingValue] = useState<
    string | undefined
  >("");
  const [inputLoading, setInputLoading] = useState<boolean>(true);

  // Handle direct back to global settings list when re-clicking on menu item. 
  const location: any = useLocation();
  const navigate = useNavigate();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
    handleConfirmation(
      MESSAGES.CONFIRM_CANCEL,
      () => {
        handleCancel();
      },
      "Confirm"
    );
  }

  // Get API
  const { } = useAPI<
    GetGlobalSettingByIdResponseItems,
    GetGlobalSettingByIdInput
  >({
    method: "GET",
    fieldName: "getGlobalSettingById",
    args: { id: id },
    onCompleted(data) {
      setGlobalSettingData({
        id: data?.id,
        value: data?.value,
        admin: data?.admin,
        updatedBy: data?.updatedBy,
        updatedAt: data?.updatedAt,
      });
      setInitialSettingValue(data?.value);
      setInputLoading(false);
    },
  });

  //API POST
  const {
    error: updateGlobalSettingsError,
    trigger: updateSetting,
    loading: updateLoading,
  } = useAPI<any, any>({
    method: "POST",
    fieldName: "updateGlobalSettings",
    manual: true,
    onCompleted: (updateGlobalSettingsData) => {
      if (!updateGlobalSettingsData || updateGlobalSettingsError) {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.UNEXPECTED_ERROR_MESSAGE
        );
        return;
      }

      if (updateGlobalSettingsData) {
        handleBannerMessage(
          COMMON.TYPE_SUCCESS,
          MESSAGES.UPDATED_SUCCESS.replace("{name}", id)
        );
        setInitialSettingValue(globalSettingData?.value);
        return;
      }
    },
  });

  const handleUpdateSetting = useCallback(() => {
    validateFormDataAsync(
      GlobalSettingsSchema,
      {
        id: id,
        value: globalSettingData?.value,
      },
      () => {
        updateSetting({ id: id, value: globalSettingData?.value });
      },
      setFormErrors
    );
  }, [GlobalSettingsSchema, updateSetting]);

  return (
    <>
      <Progress loading={inputLoading}>
        <Grid item mt={1} xs={12}>
          <StyledInput
            id={id}
            name="Global Settings Option"
            label={id}
            value={globalSettingData?.value}
            error={!!formErrors.value}
            errorText={formErrors.value}
            onChange={(e) => {
              setGlobalSettingData(
                (prevData) =>
                ({
                  ...prevData,
                  value: e.target.value,
                } as GetGlobalSettingByIdResponseItems)
              );
              console.log("globalSettingData", globalSettingData);
            }}
            onBlur={(event) => {
              validateInput(
                GlobalSettingsSchema,
                "value",
                { value: event.target.value },
                setFormErrors
              );
            }}
            multiline
            maxRows={50}
            minRows={10}
            placeholder="Setting Value"
          />
          <Grid item xs={12} mt={1}>
            <LoadingButton
              loading={updateLoading}
              sx={{
                marginRight: 1,
              }}
              variant="contained"
              onClick={() => {
                globalSettingData?.value == initialSettingValue
                  ? handleBannerMessage(COMMON.TYPE_INFO, MESSAGES.NO_CHANGES)
                  : handleUpdateSetting();
              }}
            >
              {"Update Setting"}
            </LoadingButton>
            <Button
              sx={{
                marginLeft: 1,
              }}
              variant="outlined"
              onClick={() => {
                handleConfirmation(
                  MESSAGES.CONFIRM_CANCEL,
                  () => {
                    handleCancel();
                  },
                  "Confirm"
                );
              }}
            >
              {"Cancel"}
            </Button>
          </Grid>
        </Grid>
      </Progress>
    </>
  );
};

export default React.memo(GlobalSettingOption);
