// Root
export const ROOT = "/";
// Home
export const HOME = "/home";
// SIFT
export const SIFT = "/sift";
// AMHP
export const AMHP = "/amhp";
export const AMHP_ADD = "/amhp/add";
export const AMHP_UPDATE = "/amhp/update";
// AMHP Team
export const AMHP_TEAM = "/amhp-team";
export const AMHP_TEAM_ADD = "/amhp-team/add";
export const AMHP_TEAM_UPDATE = "/amhp-team/update";
// CCG
export const CCG = "/ccg";
export const CCG_UPDATE = "/ccg/update";
export const CCG_ADD_ORG_USER = "/ccg/add-org-user";
export const CCG_UPDATE_ORG_USER = "/ccg/update-org-user";
// Doctor
export const DOCTOR = "/doctor";
export const DOCTOR_ADD = "/doctor/add";

export const DOCTOR_UPDATE = "/doctor/update";
export const DOCTOR_FIND = "/doctor/find";
export const DOCTOR_MANAGE_AVAILABILITY = "/doctor/manage-availability";
export const DOCTOR_MANAGE_AVAILABILITY_V3 = "/doctor/manage-availability-v3";
export const DOCTOR_MANAGE_TIME_AWAY = "/doctor/manage-time-away";
// Stat Forms
export const STAT_FORMS = "/stat-forms";
export const STAT_FORM_ASSIGNED_FORMS = "/stat-forms/assigned-forms";
export const STAT_FORM_USERS = "/stat-forms/users";
// General
export const GENERAL = "/general";
export const GENERAL_RESET_USER_PASSWORD = "/general/reset-user-password";
export const GENERAL_RESEND_WELCOME_EMAIL = "/general/resend-welcome-email";
export const GENERAL_CHECK_GMC = "/general/check-gmc";
export const GENERAL_GLOBAL_SETTINGS = "/general/global-settings";
export const GENERAL_CCG_AND_ICB_LOOKUP = "/general/ccg-icb-lookup";

// Sites
export const SITES = "/sites";
export const SITES_ADD = "/sites/add";
export const SITES_LINK = "/sites/link";
// MHT
export const MHT = "/mht";
export const MHT_ADD = "/mht/add";
export const MHT_UPDATE = "/mht/update";
