import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import axios from "axios";
import { AdminApi } from "api";

interface useAPIRes<TData = any, TArgs = any> {
  loading: boolean;
  data?: TData;
  error: any;
  trigger: (args?: TArgs) => Promise<
    | {
        data: TData;
        error: null;
      }
    | {
        data: null;
        error: unknown;
      }
  >;
}
interface useAPIParams<TData = any, TArgs = any> {
  method: string;
  fieldName: string;
  args?: TArgs;
  skip?: boolean;
  manual?: boolean;
  onCompleted?: (data?: TData) => void;
  onError?: (error: any) => void;
}
export const useAPI = <TData = any, TArgs = any>({
  method,
  fieldName,
  args,
  skip,
  manual,
  onCompleted,
  onError,
}: useAPIParams<TData, TArgs>): useAPIRes<TData, TArgs> => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TData>();
  const [error, setErrors] = useState<any>();

  // Create axios cancel token source
  const cancelTokenSource = axios.CancelToken.source();
  const cancelTokenSourceMemo = useMemo(
    () => cancelTokenSource,
    [cancelTokenSource]
  );
  const cancelTokenSourceRef = useRef(cancelTokenSourceMemo);
  cancelTokenSourceRef.current = cancelTokenSourceMemo;

  // manualRef
  const manualRef = useRef<boolean | undefined>(manual);
  manualRef.current = manual;

  // argsRef
  // this prevents infinite loop from args being re-rendered from being included
  // in `request` dependency array
  const argsRef = useRef<TArgs | undefined>(args);
  argsRef.current = args;

  // inProgressRef
  // this prevents multiple requests from being made concurrently
  const inProgressRef = useRef<boolean>(false);

  // runOnceRef
  // this prevents infinite loop from `request` being re-rendered
  const runOnceRef = useRef<boolean>(false);

  // On completed needs to be wrapped in useCallback to stop
  // re-rendering of the component
  const handleCompleted = useCallback(
    (data?: TData) => {
      onCompleted?.(data);
    },
    [onCompleted]
  );

  const handleError = useCallback(
    (error: any) => {
      onError?.(error);
    },
    [onError]
  );

  const request = useCallback(
    async (_args?: TArgs) => {
      if (runOnceRef.current) return { data: null, error: null };

      inProgressRef.current = true;
      runOnceRef.current = true;

      let response;
      try {
        setLoading(true);
        setErrors(undefined);
        setData(undefined);

        const _arguments = {
          ...(_args || argsRef.current),
        } as TArgs;
        const options = {
          params: {
            info: {
              fieldName,
            },
          },
          // Include cancelToken in the request
          cancelToken: cancelTokenSourceRef.current.token,
        };

        switch (method) {
          case "GET":
            response = await AdminApi.get<TData>({
              ...options,
              params: {
                ...options.params,
                arguments: _arguments,
              },
            });
            break;
          case "POST":
            response = await AdminApi.post<TData, TArgs>(_arguments, options);

            break;
          case "PUT":
            response = await AdminApi.put<TData, TArgs>(_arguments, options);

            break;
          case "DELETE":
            response = await AdminApi.delete<TData>(options);

            break;
          default:
            throw new Error(`😨 Unconfigured/invalid method ${method}`);
        }

        setData(response.data);
        handleCompleted?.(response.data);
        return { data: response.data, error: null };
      } catch (error) {
        handleError?.(error);
        setErrors(error);
        return { data: null, error: error };
      } finally {
        setLoading(false);
        inProgressRef.current = false;
      }
    },
    [fieldName, method, handleCompleted, handleError]
  );

  const handleTrigger = useCallback(
    (_args?: TArgs) => {
      runOnceRef.current = false;
      return request(_args);
    },
    [request]
  );

  useEffect(() => {
    if (
      // If skip is true
      skip ||
      // If manual is true
      manualRef.current ||
      // If method is not get
      method !== "GET" ||
      // If request is in progress
      inProgressRef.current
    )
      return;

    request();

    return () => {
      // Cleanup: cancel the request when the component unmounts and
      // reset state and refs
      cancelTokenSourceRef.current.cancel();
      setLoading(false);
      setData(undefined);
      setErrors(undefined);
    };
  }, [method, request, skip]);

  return {
    loading,
    data,
    error,
    trigger: handleTrigger,
  };
};
